/* body {
    height: 100vh;
} */

.MainTitle {
    /* line-height: 7.8rem;
    font-size: 9rem;
    font-family: "Pacifico", cursive;
    color: var(--color-primary);
    text-align: center; */
    display: flex;
    justify-content: center;
    position: relative;
    top: var(--main-title-top);

    /* font-weight: initial; */
    animation: MainTitleDown 0.6s ease-out;
    z-index: 15;
}

.MainTitleImage {
    width: var(--main-logo-width);
    border-radius: var(--main-logo-border-radius);
}

.Auth {
    position: absolute;
    top: 30%;
    left: 50%;
    width: 40rem;
    transform: translate(-50%, -30%);
}

.LabelRemember {
    font-size: 1rem;
    margin-left: 0.5rem;
    display: inline-block;
}

.FormContainer {
    padding: 3rem;
    border-radius: 10px;
    border: 1px solid #e9e9e9;
    background-color: white;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    animation: AuthAppear 0.6s ease-out;
    overflow: hidden;
    z-index: 10;
    position: relative;
}

.CheckboxContainer {
    margin-bottom: 2rem;
    margin-left: 2rem;
}

@media only screen and (max-width: 456px) {
    .Auth {
        width: 90%;
    }
}

@keyframes AuthAppear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes MainTitleDown {
    0% {
        opacity: 0;
        transform: translateY(-200px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
