.Section {
    margin-bottom: 15px;
}

.ElementList {
    margin-left: 10px;
}

.NoElements {
    color: var(--color-darker-grey);
    font-style: italic;
}

.AddButton {
    display: inline-flex;
    align-items: center;
    color: var(--color-primary);
    padding: 4px 8px;
    background-color: var(--color-lighter-grey);
    border-radius: 5px;
    margin-left: 10px;
    /* &:not(:first-child) {
        margin-top: 10px;
    } */
    cursor: pointer;
    &:hover {
        color: white;
        background-color: var(--color-primary);
    }

    .AddIcon {
        margin-right: 5px;
    }
}
