.SelectIcons {
    display: flex;
    justify-content: space-between;
    max-width: 400px;
    margin: auto;
    padding: 0px 5px;

    /* & > * {
        padding-right: clamp(5px, 22px, 22px);
        padding-left: clamp(5px, 22px, 22px);
    } */
}
