.ChecklistItems {
    display: flex;
    justify-content: center;
    margin-top: 6px;
}

.ChecklistItem {
    column-gap: 15px;
    grid-template-columns: min-content 1fr;
    display: grid;
    align-items: center;
    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

.NextItems {
    margin-bottom: 8px;
    font-weight: bold;
}
