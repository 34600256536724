.TextContent {
    font-style: italic;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 6px;

    &.Fullscreen {
        background-color: white;
        font-size: 2.2rem;
        white-space: pre-wrap;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 25px;
    }
}
