.TableGuestsManagerContainer {
    position: relative;
    overflow: hidden;
}

.TableGuestsManager {
    position: absolute;
    background-color: white;
    z-index: 3;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
}

.Times {
    cursor: pointer;
}

.GuestsAdder {
    position: absolute;
    z-index: 5;
    top: 100%;
    bottom: 0;
    right: 0px;
    left: 0px;
    transition: top 250ms;
}

.Overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    background: rgba(0, 0, 0, 0.4);
}

.Header {
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    justify-content: space-between;
    font-size: 2.4rem;
    padding: 10px 15px;
    /* box-shadow: 0 1px 3px rgba(60, 64, 67, 0.3),
        0 4px 8px 3px rgba(60, 64, 67, 0.15); */
    z-index: 40;
    color: var(--color-primary);
    background-color: white;
    border-bottom: 1px solid var(--color-light-grey);
    border-left: 1px solid var(--color-light-grey);
}

.Subheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
}

.Title {
    font-weight: bold;
}

.Seats {
    font-size: 1.4rem;

    color: var(--color-darker-grey);
    background: var(--color-warning);
    color: white;
    padding: 2px 8px;
    border-radius: 5px;
}

.GuestList {
    height: 100%;
    overflow: auto;
    font-size: 1.6rem;
    &::-webkit-scrollbar {
        width: 0px;
    }
}

.AddButton {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 0;

    font-size: 2.2rem;

    //margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0px;
    color: white;
    background: var(--color-success);
    //border-radius: 25px;
    cursor: pointer;
    user-select: none;
    &.Disabled {
        background-color: var(--color-darker-grey);
        cursor: not-allowed;
    }
}

.NoGuests {
    font-size: 1.6rem;
    color: var(--color-darker-grey);
    padding-top: 15px;
    margin: 0px 20px;

    text-align: center;
}

.Filter {
    border-bottom: 1px solid var(--color-light-grey);
    border-left: 1px solid var(--color-light-grey);
    display: grid;
    padding: 10px 12px;
}

.FilterStats {
    display: flex;
    align-items: center;
    margin-top: 5px;
    justify-content: space-between;
    font-size: 1.2rem;
}

.FilterStatsText {
    color: var(--color-darker-grey);
    padding-left: 15px;
    padding: 5px 0px;
}

@media (max-width: 992px) {
    .TableGuestsManagerContainer {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 35;
        transform: translateY(100%);
        transition: transform 200ms;
    }
}
