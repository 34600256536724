.Companions {
    justify-self: center;
}

.Category {
    justify-self: center;
}

.Actions {
    justify-self: flex-end;
}

.Status {
    justify-self: center;
}

.Phone {
    justify-self: center;
}

.GuestsHeader {
    display: grid;
    column-gap: 15px;
    grid-template-columns: /*auto*/ 1.5fr 1fr 1fr 1fr 1fr;
    align-items: center;
    padding: 0 15px;
    font-size: 1.6rem;
    font-weight: bold;
    text-transform: lowercase;
    &.SelectMode {
        grid-template-columns: auto 1.5fr 1fr 1fr 1fr 1fr;
    }
}

.SelectAll {
    white-space: nowrap;
    font-weight: normal;
    color: var(--color-primary);
}

@media (max-width: 600px) {
    .GuestsHeader {
        grid-template-columns: 1fr min-content;
        &.SelectMode {
            grid-template-columns: min-content 1fr min-content;
        }
    }
}
