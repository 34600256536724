.Checkbox {
    height: 22px;
    width: 22px;
    border: 2px solid var(--color-dark-grey);
    display: flex;
    align-items: center;
    justify-content: center;

    &.Checked {
        border-color: var(--color-primary);
        background-color: var(--color-primary);

        &.Disabled {
            border-color: var(--color-darker-grey);
            background-color: var(--color-darker-grey);
        }

        & > .Checkmark {
            color: white;
        }
    }

    &:hover:not(.Disabled) {
        cursor: pointer;
    }
    &.Disabled {
        :hover {
            cursor: not-allowed;
        }
    }
}

.Checkmark {
    font-size: 1.4rem;
}
