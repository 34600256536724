.StatsBar {
    font-size: 1.6rem;
    display: flex;
    background: white;
    padding: 6px 12px;
    border-radius: 5px;
    box-shadow: var(--box-shadow);
    & > *:not(:last-child) {
        margin-right: 25px;
    }
}

.StatsNumber {
    padding: 0px 5px;
    background-color: var(--color-success);
    color: white;
    border-radius: 5px;
}
