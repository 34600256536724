.IdeaItem {
    display: flex;
    padding: 12px 4px;
    align-items: center;
    &:last-child {
        padding-bottom: 0px;
    }
}

.Thumbnail {
    width: 45px;
    height: 45px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
    }

    .Icon {
        font-size: 4rem;
    }
}

.Link {
    font-size: 1.4rem;
    color: var(--color-primary);
}

.Colors {
    display: flex;
    margin-left: 3px;
    .Color {
        height: 15px;
        width: 15px;
        border-radius: 4px;
        &:not(:last-child) {
            margin-right: 4px;
        }
    }
}

.Text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.4rem;
}

.Details {
    flex: 1;
}

.PinIcon {
    transform: rotate(45deg);
    font-size: 2rem;
    color: var(--color-primary);
}
