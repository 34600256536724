.GuestInfo {
    align-items: center;
    display: flex;
    & > *:not(:last-child) {
        margin-right: 6px;
    }
}

.Status {
    display: flex;
    align-items: center;
}

.Companions {
    font-size: 1.6rem;
    color: var(--color-darker-grey);
}

.Icon {
    font-size: 2.2rem;
}
