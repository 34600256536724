.Spinner {
    display: flex;
    align-items: center;
}

.Text {
    margin-left: 10px;
    font-size: var(--font-size-text);
}

.SpinnerIcon {
    &:after {
        border-radius: 50%;
        width: 4.5em;
        height: 4.5em;
    }
    border-radius: 50%;
    width: 4.5em;
    height: 4.5em;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.5em solid rgba(17, 153, 158, 0.1);
    border-right: 0.5em solid rgba(17, 153, 158, 0.1);
    border-bottom: 0.5em solid rgba(17, 153, 158, 0.1);
    border-left: 0.5em solid var(--color-primary);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: rotate 700ms infinite linear;
    animation: rotate 700ms infinite linear;

    &.Small {
        &:after {
            width: 2.5em;
            height: 2.5em;
        }

        width: 2.5em;
        height: 2.5em;
        border-width: 0.2em;
    }

    &.Big {
        &:after {
            width: 7.5em;
            height: 7.5em;
        }

        width: 7.5em;
        height: 7.5em;
        border-width: 0.6em;
    }
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
