.GuestsInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.FilteredNumber {
    color: var(--color-muted-black);
    margin-left: 10px;
    font-size: 1.2rem;
}
