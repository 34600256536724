.LinkContent {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.Image {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.Details {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: white;
    padding: 4px 6px;
}

.Description {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 1.2rem;
    margin-bottom: 2px;
    text-align: left;
}

.Title {
    white-space: nowrap;
    overflow: hidden;
    font-size: 1.4rem;
    text-overflow: ellipsis;
    text-align: left;
    margin-bottom: 2px;
}

.Link {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: var(--color-primary-shade);
    &.NoTitleLink {
        font-size: 1.6rem;
        color: white;
    }
}

.LinkIcon {
    margin-right: 6px;
    font-size: 1.2rem;
}

.NoImageIcon {
    font-size: 4.2rem;
    align-self: flex-start;
    position: absolute;
    top: 25%;
    &.Centered {
        top: 50%;
        transform: translateY(-50%);
    }
}
