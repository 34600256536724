.Layout {
    display: grid;
    grid-template-columns: 4fr minmax(360px, 1fr);
    &.SinglePane {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 992px) {
    .Layout {
        grid-template-columns: 1fr;
    }
}
