.NoLayout {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    //transform: translate(-50%, -50%);
    display: inline-flex;
    flex-flow: column;
    align-items: center;
    margin-top: 25px;
    max-width: 800px;
}

.NoLayoutTitle {
    text-align: center;
}

.NoLayoutSubtitle {
    font-size: 1.8rem;
    color: var(--color-darker-grey);
    text-align: center;
    margin-bottom: 15px;
    margin-top: 10px;
}

.SugestionsText {
    margin-top: 15px;
    font-size: 1.8rem;
    color: var(--color-darker-grey);
    margin-bottom: 10px;
}

.LayoutExample {
    width: 450px;
}

.ImageExample {
    width: 850px;
    border: 2px solid var(--color-primary);
    border-radius: 5px;
}
