.Input {
    margin-bottom: 1.5rem;
    &.Inline {
        margin-bottom: 0px;
        .InputElement {
            border-radius: 0px;
            border: 0px;
            border-bottom: 1px solid #e9e9e9;
            padding: 0px;
            &:focus {
                border-bottom-color: var(--color-primary);
                outline: none;
            }
        }
    }

    &.HasError .InputElement {
        border-color: var(--color-alert);
    }
}

.InputElement {
    display: block;
    width: 100%;

    border-radius: 100px;
    border: 1px solid #e9e9e9;
    font-size: var(--font-size-text);
    padding: 10px 20px;
    position: relative;
    z-index: 2;
    font-family: "Poppins";

    &:focus {
        border: 1px solid var(--color-primary);
        border-radius: 100px;
        outline: none;
    }

    &::placeholder {
        color: #cecece;
    }

    &.TextArea {
        border-radius: 10px;
        padding: 6px 12px;
    }
}

.Label {
    font-size: var(--font-size-text);
    margin-left: 2rem;
    display: inline-block;
    font-weight: bold;
    margin-bottom: 0.4rem;
}

.StepperContainer {
    padding-left: 15px;
}

.MessageEnter {
    transform: translateY(-100%);
    opacity: 1 !important;
    //opacity: 0;
}

.MessageEnterActive {
    transform: translateY(0);
    transition: transform 500ms;
    //opacity: 1;
}

.MessageEnterDone {
    opacity: 1 !important;
}

.MessageExit {
    transform: translateY(0);
    opacity: 1 !important;
}

.MessageExitActive {
    transform: translateY(-100%);
    transition: transform 500ms;
    opacity: 1 !important;
}

.MessageExitDone {
    transform: translateY(-100%);
    //opacity: 0;
}
