.TemporalTable {
    position: absolute;
}

.TableLayout {
    position: relative;
    margin: 0 auto;
    transition: width 100ms;
    user-select: none;
    position: relative;
    width: 1380px;
}

@media (max-width: 1400px) {
    .TableLayout {
        width: 1156px;
    }
}

@media (max-width: 1200px) {
    .TableLayout {
        width: 978px;
    }
}

@media (max-width: 992px) {
    .TableLayout {
        width: 100%;
    }
}
