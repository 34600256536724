.GuestRowContainer {
    border-bottom: 1px solid var(--color-light-grey);
}

.GuestRow {
    display: flex;
    justify-content: space-between;
    border-left: 5px solid var(--color-success);
    padding: 10px 15px;
    user-select: none;
    &:hover {
        background-color: var(--color-light-grey);
        cursor: pointer;
    }
    align-items: center;
}

.GuestContainer {
    display: flex;
    align-items: center;
}

.GuestName {
    margin-left: 10px;
    white-space: nowrap;
}

.Companion {
    display: flex;
    align-items: center;
    padding: 6px;
    padding-left: 25px;
    &:hover {
        background-color: var(--color-light-grey);
        cursor: pointer;
    }
}

.CompanionsDetail {
    overflow: hidden;
    transition: height 200ms;
}

.CompanionsLeft {
    color: var(--color-darker-grey);
    display: flex;
    align-items: center;
    padding: 5px 8px;
    user-select: none;
    &.Active {
        color: var(--color-primary);
    }
}

.AngleRight {
    margin-right: 10px;
    font-size: 1.6rem;
    transition: transform 200ms;
}
