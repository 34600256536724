.Container {
    width: 1380px;
    margin: 0 auto;
    padding: 0px 15px;
    position: relative;
    height: 100%;
}

@media (max-width: 1400px) {
    .Container {
        width: 1156px;
    }
}

@media (max-width: 1200px) {
    .Container {
        width: 978px;
    }
}

@media (max-width: 992px) {
    .Container {
        width: 100%;
    }
}
