.IdeaFilters {
    display: flex;
    align-items: center;
    & > *:not(:last-child) {
        margin-right: 15px;
    }
}

.TitleFilterContainer {
    max-width: 750px;
    flex: 1;
}

.FilterIcon {
    font-size: 1.8rem;
    color: var(--color-primary);
}

.CategoryFilterContainer,
.TypeFilterContainer {
    max-width: 200px;
    flex: 1;
}

.TypeFilterMoreContainer,
.CategoryFilterMoreContainer {
    display: none;
}

.MoreFiltersContainer {
    position: relative;
    display: none;
}

.MoreFiltersLabel {
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px;
    font-size: 1.6rem;
}

.MoreFiltersIcon {
    height: 34px;
    width: 34px;
    border-radius: 100%;
    border: 1px solid var(--color-dark-grey);
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: white;
    cursor: pointer;

    .Icon {
        font-size: 2rem;
    }
}

@media (max-width: 960px) {
    .MoreFiltersContainer {
        display: block;
    }
    .TypeFilterContainer {
        display: none;
    }
    .TypeFilterMoreContainer {
        display: block;
    }
}

@media (max-width: 600px) {
    .CategoryFilterContainer {
        display: none;
    }
    .CategoryFilterMoreContainer {
        display: block;
    }
    .FilterIcon {
        display: none;
    }
}
