.OptionsTable {
    font-size: 1.6rem;
    padding: 5px;
    margin-bottom: 5px;
    tr:not(:last-child) > td {
        padding-bottom: 10px;
    }
}

.OptionLabel {
    white-space: nowrap;
    padding-right: 15px;
}

.Title {
    font-weight: bold;
    text-align: center;
    font-size: 1.6rem;
    margin: 5px 0px;
    margin-left: 5px;
}

.ResetButton {
    display: flex;
    justify-content: center;
}

.OptionLabel {
    //min-width: 150px;
    width: 100%;
}
