.StatusMessage {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: white;
    padding: 5px 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    left: 5px;
    right: 5px;
    position: absolute;
    z-index: 5;
    /* opacity: 0; */
    /* display: none;
    transform: translateY(-110%); */

    &.Alert {
        background-color: var(--color-alert);
    }

    &.Warning {
        background-color: var(--color-warning);
    }

    &.Success {
        background-color: var(--color-success);
    }
}

.Message {
    flex: 1;
}

.Times {
    transition: transform 100ms;
    margin-left: 10px;
    &:hover {
        cursor: pointer;
        transform: scale(1.2);
    }
}

.Icon {
    margin-right: 10px;
}

.Enter {
    transform: translateY(-110%);
    //opacity: 1;
    display: flex;
}

.EnterActive {
    transform: translateY(0) !important;
    transition: transform 500ms;
}

.EnterDone {
    transform: translateY(0) !important;
    /* opacity: 1; */
    display: flex;
}

.Exit {
    transform: translateY(0) !important;
    /* opacity: 1; */
    display: flex;
}

.ExitActive {
    transform: translateY(-110%) !important;
    transition: transform 500ms;
    /* opacity: 1; */
    display: flex;
}
