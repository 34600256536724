.GuestCard {
    background-color: white;
    box-shadow: var(--box-shadow);
    z-index: 1020;
    position: fixed;
    right: 20px;
    left: 20px;
    top: 30px;
    bottom: 30px;
}

.Controls {
    padding: 10px;
    padding-top: 25px;
    padding-bottom: 50px;
    position: relative;
}

.DeleteButton {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
    background-color: var(--color-success);
    color: white;
    font-size: 2rem;
    z-index: 25;
}

.Content {
    position: relative;
    overflow: auto;
    height: 100%;
}

.ActionButtons {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    z-index: 25;
    & > *:not(:last-child) {
        margin-right: 10px;
    }
}

.DeleteContainer {
    display: flex;
    justify-content: center;
}

.Label {
    font-weight: bold;
    margin-bottom: 2px;
    font-size: 1.4rem;
}

.Enter {
    opacity: 0;
}

.EnterActive {
    opacity: 1;
    transition: opacity 200ms;
}

.Exit {
    opacity: 1;
}

.ExitActive {
    transition: opacity 200ms;
    opacity: 0;
}
