.HistoryTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 15px;
    thead {
        th {
            text-align: left;
            padding: 0px 10px;
            padding-bottom: 0px;
            &:not(:first-child) {
                text-align: center;
            }
        }
    }
    tbody {
        td {
            padding: 12px;
            &:not(:first-child) {
                text-align: center;
            }
        }
        tr {
            background: white;
            box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow-light);
            /*  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15); */
        }
    }

    .Centered {
        text-align: center;
    }
}

.LineContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Line {
    height: 1px;
    width: 40px;
    background-color: var(--color-darker-grey);
}
