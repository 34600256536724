.SupplierItem {
    display: flex;
    padding: 0px 16px;
    border-bottom: 1px solid var(--color-grey);
    align-items: center;
    justify-content: space-between;

    &:hover {
        cursor: pointer;
        background-color: var(--color-light-grey);
    }

    .Text {
        padding: 12px 0px;
    }
}
