.Content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 25px;
}

.UsersHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2.2rem;
    color: var(--color-primary);
    margin-right: 5px;

    .PlusIcon {
        cursor: pointer;
    }
}

.SubtitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.SelectAll {
    color: var(--color-primary);
    align-self: flex-end;
    font-size: 1.4rem;
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}
