.AttendanceManager {
    position: relative;
    overflow: hidden;
}

.GuestList {
    height: 100%;
    overflow: auto;
    font-size: 1.6rem;
    &::-webkit-scrollbar {
        width: 0px;
    }
}

.HeaderContainer {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-light-grey);
    border-left: 1px solid var(--color-light-grey);
    padding: 16px;

    .ToggleContainer {
        flex: 1;
    }

    .Times {
        font-size: 3rem;
        color: var(--color-alert);
        margin-left: 15px;
    }
}

.NoGuests {
    font-size: 1.6rem;
    color: var(--color-darker-grey);
    padding-top: 15px;
    margin: 0px 20px;

    text-align: center;
}

.NoGuestsTable {
    font-size: 1.6rem;
    color: var(--color-darker-grey);
    margin: 0px 20px;
}

.Subtitle {
    color: var(--color-primary);
    padding: 10px;
    font-size: 2rem;
    font-weight: bold;
}

@media (max-width: 992px) {
    .AttendanceManager {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 35;
        transform: translateY(100%);
        transition: transform 200ms;
    }
}
