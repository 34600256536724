.TableLayout {
    position: relative;
    margin: 0 auto;
    /* transition: width 100ms; */
    user-select: none;
    position: relative;
    width: 1380px;
}

.StatsContainer {
    position: relative;
}

.Stats {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1.6rem;
    background-color: white;
    box-shadow: var(--box-shadow);
    padding: 6px 12px;
    border-radius: 10px;
    margin-top: 5px;
    transition: transform 200ms;
    overflow: hidden;
    transform-origin: top left;

    &.Hidden {
        transform: scale(0);
    }
    td {
        white-space: nowrap;
        &:not(:last-child) {
            padding-right: 20px;
            color: var(--color-primary);
            /* font-weight: bold; */
        }
        &:last-child {
            text-align: right;
        }
    }
    .OccupiedPlaces {
        color: var(--color-success);
    }
    .AvailablePlaces {
        color: var(--color-warning);
    }
}

.OccupiedPlaces {
    color: var(--color-success);
}
.AvailablePlaces {
    color: var(--color-warning);
}

.HideContainer {
    display: flex;
    justify-content: center;
}

.TopMessage {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    box-shadow: var(--box-shadow);
    background: white;
    padding: 6px 12px;
    border-radius: 5px;
    background-color: var(--color-primary);
    color: white;
}

.Info {
    margin-right: 10px;
    font-size: 1.8rem;
}

.Times {
    font-size: 1.8rem;
    margin-left: 10px;
    cursor: pointer;
}

.Options {
    display: flex;
    flex-flow: column;
    z-index: 20;
    & > *:not(:last-child) {
        margin-bottom: 5px;
    }
}

.MiniButton {
    font-size: 1.6rem;
    padding: 2px 16px;
    border-radius: 25px;
    cursor: pointer;
    /* display: none; */

    &.Primary {
        color: white;
        background-color: var(--color-primary);
    }
    &.Alert {
        color: var(--color-alert);
        background-color: var(--color-light-alert);
    }
}

.HostessIconContainer {
    margin-top: 35px;
}

@media (max-width: 1400px) {
    .TableLayout {
        width: 1156px;
    }
}

@media (max-width: 1200px) {
    .TableLayout {
        width: 978px;
    }
}

@media (max-width: 992px) {
    .TableLayout {
        width: 100%;
    }
    .ShowListButton {
        display: flex;
    }
}
