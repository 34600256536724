.ChecklistItem {
    font-size: var(--font-size-text);
    display: flex;
    column-gap: 15px;
    grid-template-columns: min-content 1fr min-content;
    align-items: center;
    padding: 6px 14px;
    //padding-top: 7px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
    //border: 1px solid transparent;
    position: relative;

    &.EditMode {
        grid-template-columns: 1fr auto min-content;
    }

    /*     &:not(.EditMode):not(.NotClickable) {
        cursor: pointer;
    } */
}

.CheckComponentContainer {
    margin-right: 15px;
}

.TimeManagerContainer {
    margin-left: 15px;
}

.ActionButtons {
    display: flex;
    align-items: center;
    margin-left: 15px;
    & > :not(:last-child) {
        margin-right: 10px;
    }
}

.TextContainer {
    position: relative;
    flex: 1;
}

.Check {
    font-size: 2.6rem;
    color: var(--color-success);
    &.NotCompleted {
        visibility: hidden;
    }
}
