.Input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    font-size: var(--font-size-text);
    // font-size: 1.4rem;
    padding: 8px 0px;
    border: 0px;
    font-family: "Poppins", sans-serif;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: #cecece;
    }
}

.Icon {
    font-size: 1.6rem;
}

.IconLeft {
    margin-right: 15px;
}

.InputIcon {
    width: 100%;
    border-radius: 100px;
    border: 1px solid #e9e9e9;

    display: inline-flex;
    align-items: center;
    background-color: white;
    padding: 0px 20px;
    justify-content: space-between;

    &:focus-within {
        border: 1px solid var(--color-primary);
        border-radius: 100px;
    }

    &.HasError {
        border-color: var(--color-alert);
    }
}

.Times {
    color: var(--color-alert);
    margin-left: 10px;
    font-size: 2.2rem;
    cursor: pointer;
}

.InputIconSubContainer {
    display: flex;
    align-items: center;
    width: 100%;
}
