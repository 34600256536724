.List {
    flex-grow: 1;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-track {
        background: hsla(0, 0%, 100%, 0.1);
    }
    scrollbar-color: rgba(0, 0, 0, 0.2) rgba(255, 255, 255, 0.1);
    scrollbar-width: thin;
}
.SearchBar {
    background-color: var(--color-grey);
    padding: 15px 5px;
    padding-right: 15px;
    display: flex;
    align-items: center;
}

.Item {
    display: flex;
    padding: 12px 16px;
    border-bottom: 1px solid var(--color-grey);
    align-items: center;
    justify-content: space-between;

    &:hover {
        cursor: pointer;
        background-color: var(--color-light-grey);
    }
}

.Message {
    color: var(--color-dark-grey);
    margin-top: 15px;
    text-align: center;
    font-style: italic;
    padding: 0px 15px;
}
