.GuestFilters {
    display: grid;
    align-items: center;
    column-gap: 15px;
    grid-template-columns: auto 2.5fr 1fr 1fr 1fr auto auto;
}

.FilterIcon {
    font-size: 1.8rem;
    color: var(--color-muted-black);
}

.MoreButton {
    border: 1px solid var(--color-dark-grey);
    background-color: white;
    width: 3rem;
    height: 3rem;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    position: relative;

    &.HasFilters {
        background-color: var(--color-primary);
        color: white;
    }

    &:hover {
        cursor: pointer;
        &:not(.HasFilters) {
            background-color: var(--color-muted-black);
            border-color: 1px solid var(--color-muted-black);
            color: white;
        }
    }
}

.Icon {
    font-size: 1.8rem;
}

.Badge {
    padding: 5px;
    position: absolute;
    right: -10px;
    top: -10px;
    background-color: var(--color-alert);
    //border: 1px solid var(--color-dark-alert);
    border-radius: 100%;
    width: 20px;
    height: 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}

.MoreFiltersContainer {
    position: relative;
}

.MoreFiltersLabel {
    font-weight: bold;
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 10px;
}

.GuestFiltersContainer {
    position: relative;
}

@media (max-width: 600px) {
    .GuestFilters {
        grid-template-columns: 2.5fr auto;
    }

    .FilterIcon {
        display: none;
    }
}
