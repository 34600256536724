.Stepper {
    display: flex;
    /*  justify-content: center; */
    align-items: center;
    font-size: var(--font-size-text);
}

.IconContainer {
    padding: 3px 15px;
    &.Disabled {
        opacity: 0.3;
        &:hover {
            cursor: not-allowed;
        }
    }
    &:hover:not(.Disabled) {
        color: var(--color-primary);
        cursor: pointer;
    }
}

.Value {
    user-select: none;
}
