.LanguageSelector {
    display: flex;
    align-items: center;
    & > :not(:last-child) {
        margin-right: 10px;
    }
    padding: 2.5px 10px;
    justify-content: space-between;
    font-size: var(--font-size-text);

    &:hover {
        cursor: pointer;
        background-color: var(--color-light-grey);
    }
}

.Icon {
}

.Option {
    flex: 1;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
}

.Selected {
    color: var(--color-darker-grey);
}

.LanguageSelectorContainer {
    position: relative;
}
