.GuestSelectOptions {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 5px;
    //border: 1px solid var(--color-primary);
    position: fixed;
    z-index: 50;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    box-shadow: var(--box-shadow-light);
    padding: 6px 12px;
    border-radius: 5px;
    & > *:not(:last-child) {
        margin-right: 20px;
    }
    font-size: 1.6rem;

    &.Enter {
        transform: translate(-50%, calc(100% + 15px));
    }

    &.EnterActive {
        transform: translate(-50%, 0px);
        transition: transform 250ms;
    }

    &.ExitActive {
        transform: translate(-50%, calc(100% + 15px));
        transition: transform 250ms;
    }

    &.ExitDone {
        transform: translate(-50%, calc(100% + 15px));
    }
}

.Option {
    display: flex;
    align-items: center;
    & > *:not(:last-child) {
        margin-right: 10px;
    }
    padding: 2px 10px;
    &:hover {
        cursor: pointer;
        background-color: #f4f4f4;
    }
}

.Delete {
    color: var(--color-alert);
}

.ChangeAttribute {
    color: var(--color-primary);
}

.Cancel {
    color: var(--color-muted-black);
}

.SelectIcon {
    font-size: 1.8rem;
    color: var(--color-muted-black);
}

.OptionContainer {
    position: relative;
}

.GuestSelectOptionsMobile {
    position: fixed;
    bottom: 15px;
    left: 10px;
    right: 10px;
    box-shadow: 0 1px 3px rgba(60, 64, 67, 0.3),
        0 4px 8px 3px rgba(60, 64, 67, 0.15);
    background-color: white;
    z-index: 55;
    border-radius: 5px;
    font-size: 2.2rem;

    .SelectOptions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        padding-top: 0px;
    }
    .SelectInfo {
        //font-weight: bold;
        color: white;
        background-color: var(--color-primary);
        text-align: right;
        font-size: 1.6rem;
        display: flex;
        justify-content: space-between;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        margin-bottom: 10px;
        padding: 10px;

        .Cancel {
            color: white;
        }
    }
    &.Enter {
        transform: translateY(calc(100% + 25px));
    }

    &.EnterActive {
        transform: translateY(0px);
        transition: transform 250ms;
    }

    &.ExitActive {
        transform: translateY(calc(100% + 25px));
        transition: transform 250ms;
    }

    &.ExitDone {
        transform: translateY(calc(100% + 25px));
    }
}
