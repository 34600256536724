.ValidationTable {
    margin-top: 10px;
    border-collapse: collapse;
    margin-bottom: 10px;
    width: 100%;
    th {
        text-align: left;
        padding: 5px;
    }

    td {
        padding: 10px;
        border: 1px solid var(--color-dark-grey);
        word-wrap: break-word;
        width: auto;
        &:first-child {
            background-color: var(--color-light-alert);
        }
    }
}
