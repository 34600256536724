.Month {
  display: inline-grid;
  grid-template-columns: repeat(7, 1fr);
  background-color: white;
  padding: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 2px solid var(--color-primary);
  border-top: 0;
}

.DayOfWeek {
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  padding: 5px;
  user-select: none;
}
