.Toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    /* border: 1px solid black; */
    position: relative;
    background-color: var(--color-light-grey);
    border-radius: 20px;
    flex: 1;
    .ToggleOption {
        position: relative;
        padding: 5px 0px;
        /*  width: 50%; */
        flex: 1;
        display: flex;
        justify-content: center;
        z-index: 2;
        cursor: pointer;
        text-align: center;
        &.Selected {
            color: white;
        }
    }
    .Selection {
        border-radius: 20px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: var(--color-primary);
        z-index: 1;
        transition: left 200ms;
        /*  border-radius: 15px 0px 0px 15px; */
        &.Inside {
            left: 50%;
            /*  border-radius: 0px 15px 15px 0px; */
        }
    }
}
