.ColorSelect {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    position: relative;
    /*  min-height: 260px; */
    align-items: flex-start;
    align-items: center;
    background-color: var(--color-light-grey);
    border-radius: 10px;
    padding: 10px;
    gap: 5px;
    justify-content: center;
    z-index: 2;
    & > * {
        padding-bottom: 100%;
        position: relative;
    }
}

.AddColor {
    border-radius: 5px;
    border: 2px dashed var(--color-primary);
    color: var(--color-primary);
    cursor: pointer;

    .Plus {
        position: absolute;
        top: 50%;
        left: 50%;
        right: 50%;
        bottom: 50%;
        transform: translate(-50%, -50%);
        font-size: 5rem;
    }
}
