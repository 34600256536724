.ContextSpinner {
    font-size: 10px;
    //  margin: 50px auto;
    text-indent: -9999em;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(
        left,
        #ffffff 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: -webkit-linear-gradient(
        left,
        #ffffff 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: -o-linear-gradient(
        left,
        #ffffff 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: -ms-linear-gradient(
        left,
        #ffffff 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: linear-gradient(
        to right,
        #ffffff 10%,
        rgba(255, 255, 255, 0) 42%
    );
    position: relative;
    -webkit-animation: load3 0.7s infinite linear;
    animation: load3 0.7s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
.ContextSpinner::before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
}
.ContextSpinner::after {
    background: var(--color-primary);
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.ContextSpinner.Alert::after {
    background-color: var(--color-alert);
}

.ContextSpinner.Success::after {
    background-color: var(--color-success);
}

.ContextSpinner.Default {
    &::after {
        background-color: var(--color-background);
    }
    &::before {
        background-color: var(--color-primary);
    }
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
