.ColorPicker {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 3;
    transform: translateY(-100%);
    &.Right:not(.Mobile) {
        left: -50%;
        transform: translate(-50%, -100%);
    }
    &.Mobile {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1100;
    }
}

.ColorOption {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 5px;
    cursor: pointer;
}

.ColorContainer {
    position: relative;
}

.OptionsOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
    z-index: 2;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    & > *:not(:last-child) {
        margin-right: 5px;
    }
}

.DeleteIconContainer {
    position: absolute;
    left: 100%;
    transform: translate(-50%, -50%);
    height: 25px;
    width: 25px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    color: var(--color-alert);
    border: 2px solid var(--color-alert);
    background-color: white;
    z-index: 2;
}

.OptionIcon {
    font-size: 1.4rem;
}
