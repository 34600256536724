.Editable {
    border: 0px;
    border-bottom: 1px solid var(--color-dark-grey);
    background-color: white;
    outline: none;
    width: 100%;
    position: relative;
    font-size: 1.6rem;
    z-index: 1;
    font-family: "Poppins";
    padding: 2px;
    &:focus,
    &:active,
    &:visited {
        border: 0px;
        border-bottom: 1px solid var(--color-primary);
    }
    &::placeholder {
        color: var(--color-dark-grey);
    }
}

.Text {
    font-size: 1.6rem;
    /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; */
}
