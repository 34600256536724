.CommentsInput {
    border: 1px solid var(--color-dark-grey);
    border-radius: 5px;
    outline: none;
    width: 100%;
    font-size: var(--font-size-text);
    font-family: "Poppins", sans-serif;
    position: relative;
    z-index: 1;
    padding: 5px;
    &:focus {
        border-color: var(--color-primary);
    }
}

.CommentsManager {
    font-size: var(--font-size-text);
    font-family: "Poppins", sans-serif;
}

.Name {
    border-bottom: 1px solid transparent;
    white-space: pre-wrap;
}

.Label {
    font-weight: bold;
    font-size: 1.4rem;
    margin-bottom: 2px;
}

.MessageEnter {
    transform: translateY(-100%);
    opacity: 1 !important;
    //opacity: 0;
}

.MessageEnterActive {
    transform: translateY(0);
    transition: transform 500ms;
    //opacity: 1;
}

.MessageEnterDone {
    opacity: 1 !important;
}

.MessageExit {
    transform: translateY(0);
    opacity: 1 !important;
}

.MessageExitActive {
    transform: translateY(-100%);
    transition: transform 500ms;
    opacity: 1 !important;
}

.MessageExitDone {
    transform: translateY(-100%);
    //opacity: 0;
}
