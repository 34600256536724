.ContextMessage {
    //margin-left: 2rem;
    font-size: 1.2rem;
    color: var(--color-alert);
    padding: 2px 6px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: 3px;
    z-index: -1;
    display: flex;
    align-items: center;
    transform: translateY(-100%);
    &.Alert {
        color: var(--color-alert);
    }

    &.Warning {
        color: var(--color-warning);
    }

    &.Success {
        color: var(--color-success);
    }
}

.Message {
    margin-left: 6px;
}

.EnterActive {
    transform: translateY(0);
    transition: transform 500ms;
}

.EnterDone {
    transform: translateY(0);
}

.Exit {
    transform: translateY(0);
}

.ExitActive {
    transform: translateY(-100%);
    transition: transform 500ms;
}
