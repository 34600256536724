.Overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1000;
    opacity: 0;
    transition: opacity 200ms;

    &.Show {
        opacity: 1;
    }
}

.Hidden {
    display: none;
}

.Enter,
.ExitDone,
.ExitActive {
    opacity: 0;
}

.EnterActive,
.EnterDone {
    opacity: 1;
}

@media (min-width: 601px) {
    .Overlay {
        &.MobileOnly {
            display: none;
        }
    }
}
