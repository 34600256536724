.Label {
    font-size: var(--font-size-text);
    margin-left: 2rem;
    display: inline-block;
    font-weight: bold;
    margin-bottom: 0.4rem;
}

.InputContainer {
    margin-bottom: 1.5rem;
}

.Value {
    margin-left: 25px;
}

.NewBalance {
    font-size: 1.4rem;
    padding-left: 25px;
    margin-bottom: 15px;
    margin-top: 4px;
}
