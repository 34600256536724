.SelectedSortContainer {
    display: flex;
    align-items: center;
    padding: 10px 8px;
    font-size: 1.4rem;
}

.SortSelector {
    position: relative;
    cursor: pointer;
    user-select: none;
}

.SortLabel {
    margin-right: 10px;
}

.SelectedSort {
    color: var(--color-primary);
    display: flex;
    align-items: center;
}

.SelectedSortValue {
    margin-right: 5px;
}
