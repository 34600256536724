.ColorSelect {
    position: relative;
}

.SelectedColor {
    color: white;
    width: 18px;
    height: 18px;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ColorPicker {
    position: absolute; /* 
    transform: translateY(-100%);
    top: -5px; */
    z-index: 4;
    transform: translateY(5px);
}
