.Countdown {
    display: flex;
    justify-content: center;
    /* color: var(--color-primary); */
    .TimeContainer {
        display: flex;
        flex-flow: column;
        align-items: center;

        &:not(:last-child) {
            margin-right: 35px;
        }
    }

    .Time {
        font-size: 5.2rem;
        line-height: 4.4rem;
        margin-bottom: 4px;
        color: var(--color-primary);
    }
}

@media (max-width: 600px) {
    .Countdown {
        display: flex;
        justify-content: space-between;
        margin-left: 5px;
        margin-right: 5px;
        .TimeContainer {
            &:not(:last-child) {
                margin-right: 0;
            }
        }
        .Time {
            font-size: 4.6rem;
            line-height: 3.8rem;
            margin-bottom: 4px;
            color: var(--color-primary);
        }
    }
}
