.Both {
    display: flex;
    & > :not(:last-child) {
        margin-right: 15px;
    }
}

.TotalContainer {
    position: relative;
}

.Line {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--color-dark-success);
    top: 50%;
    transform: rotate(5deg);
}
