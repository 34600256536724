.UploadFileButton {
    z-index: 2;
    margin-left: 10px;
    position: relative;
}

.UploadFileInlineButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--color-primary);
}

.UploadFileInline {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.UploadIcon {
    margin-right: 10px;
}

.Value {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.RemoveFileIcon {
    color: var(--color-alert);
    font-size: 1.8rem;
    margin-left: 15px;
    cursor: pointer;
}
