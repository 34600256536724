.ContentViewer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.8);
    z-index: 2000;
}

.ExitIcon {
    font-size: 4.8rem;
    color: white;
}

.Content {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2080px;
    /* background-color: rosybrown; */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ExitIconContainer {
    background-color: rgba($color: #000000, $alpha: 0.4);
    position: absolute;
    right: 25px;
    top: 15px;
    z-index: 4;
    cursor: pointer;
    padding: 6px 10px;
    border-radius: 5px;
}

@media (max-width: 2240px) {
    .Content {
        width: 1756px;
    }
}

@media (max-width: 1907px) {
    .Content {
        width: 1657px;
    }
}

@media (max-width: 1791px) {
    .Content {
        width: 1428px;
    }
}

@media (max-width: 1452px) {
    .Content {
        width: 100%;
    }
}
