.ProgressBar {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: var(--color-grey);
    border-radius: 15px;
    //border: 1px solid var(--color-muted-black);
}

.Bar {
    position: absolute;
    background-color: var(--color-light-success);
    transition: width 1000ms;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 15px;
}

.ProgressText {
    padding: 5px 0px;
    z-index: 5;
    text-align: center;
    //color: white;
}
