.AddTableOption {
    position: relative;
}
.PlusIcon {
    margin-right: 10px;
}

.AddTableType {
    display: flex;
    padding: 10px;
    align-items: center;
    font-size: 1.8rem;
    justify-content: center;
    color: var(--color-primary);
    &:hover {
        background-color: var(--color-light-grey);
        cursor: pointer;
    }
}

.AddTableTypeContainer {
    text-align: center;
}
