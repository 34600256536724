.MultiSelectAction {
    display: flex;
    align-items: center;
    & > *:not(:last-child) {
        margin-right: 10px;
    }
    padding: 2px 10px;
    color: var(--color-primary);
    &:hover {
        cursor: pointer;
        background-color: #f4f4f4;
    }

    &.Alert {
        color: var(--color-alert);
    }
}
