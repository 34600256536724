.PaymentItem {
    display: flex;
    padding: 0px 10px;
    border-bottom: 1px solid var(--color-grey);
    align-items: center;

    &:hover:not(.EditMode) {
        cursor: pointer;
        background-color: var(--color-light-grey);
    }
}

.Text {
    flex-grow: 1;
    padding: 12px 0px;
}

.Date {
    margin-right: 15px;
    font-size: 1.4rem;
    color: var(--color-darker-grey);
}

.DateMobile {
    font-size: 1.2rem;
    color: var(--color-darker-grey);
    margin-left: 5px;
}

.Amount {
    padding-left: 5px;
}

.ActionButtons {
    margin-left: 15px;
    display: flex;
    & > *:not(:last-child) {
        margin-right: 5px;
    }
}
