.TableGuestsAdder {
    position: absolute;
    z-index: 5;
    top: 100%;
    bottom: 0;
    right: 0px;
    left: 0px;
    transition: top 250ms;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.Header {
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    justify-content: space-between;
    font-size: 2.4rem;
    padding: 10px 15px;
    color: var(--color-primary);
    background-color: white;
    /*     border-bottom: 1px solid var(--color-light-grey);
    border-left: 1px solid var(--color-light-grey); */
    background-color: var(--color-success);
    color: white;
}

.Filter {
    border-bottom: 1px solid var(--color-light-grey);
    border-left: 1px solid var(--color-light-grey);
    padding: 10px 12px;
    padding-bottom: 5px;
}

.TitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.TitleIcon {
    display: flex;
    align-items: center;
}

.FilterStats {
    display: flex;
    align-items: center;
    margin-top: 5px;
    justify-content: space-between;
    font-size: 1.2rem;
}

.FilterStatsText {
    color: var(--color-darker-grey);
    padding-left: 15px;
    padding: 5px 0px;
}

.Times {
    cursor: pointer;
}

.Plus {
    margin-right: 10px;
}

.Subtitle {
    font-weight: normal;
    color: var(--color-darker-grey);
    font-size: 1.4rem;
    margin-right: 25px;
    background-color: var(--color-darker-grey);
    color: white;
    padding: 0px 4px;
    border-radius: 5px;
    //position: relative;
    //top: -8px;
}

.GuestList {
    overflow: auto;
    font-size: 1.6rem;
    &.NoGuests {
        color: var(--color-darker-grey);
        padding-top: 15px;
        margin: 0px 20px;
        text-align: center;
    }
    &::-webkit-scrollbar {
        width: 0px;
    }
}

.AddButton {
    position: absolute;
    bottom: 10px;
    transform: translateY(calc(100% + 10px));
    left: 15px;
    right: 15px;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    border-radius: 25px;
    color: white;
    background: var(--color-primary);
    cursor: pointer;
    user-select: none;
    transition: transform 200ms;
}

.Trash {
    color: var(--color-alert);
}

.EmptyTable {
    font-size: 1.6rem;
    color: var(--color-darker-grey);
    padding-top: 15px;
    margin: 0px 20px;

    text-align: center;
}

/* .MessageInfo {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    transition: height 200ms;
    border-bottom: 1px solid var(--color-light-grey);
    color: white;
    background-color: var(--color-alert);
    overflow: hidden;
    padding: 0px 15px;
}

.Exclamation {
    margin-right: 15px;
    font-size: 2.2rem;
}

.MessageInfoText {
    padding: 10px 0px;
}
 */
