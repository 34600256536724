.Guest {
    display: flex;
    border-left: 5px solid #cecdca;
    padding: 8px 15px;
    border-bottom: 1px solid var(--color-light-grey);
    align-items: center;
}

.GuestInformation {
    flex: 1;
    display: flex;
    align-items: center;
}

.Companion {
    margin-left: 10px;
    //font-size: 1.4rem;
    color: var(--color-darker-grey);
}

.TableNumber {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    font-size: 1.3rem;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    border: 1px solid var(--color-warning);
    user-select: none;
    &.CanClick {
        cursor: pointer;
    }
}

.IconContainer {
    cursor: pointer;
    padding: 5px;
    margin-right: 5px;
}

.Trash {
    color: var(--color-alert);
    font-size: 1.8rem;
    &:hover {
        color: var(--color-dark-alert);
        cursor: pointer;
    }
}

.DoorOpen {
    color: var(--color-success);
    font-size: 2.2rem;
    &:hover {
        color: var(--color-dark-success);
        cursor: pointer;
    }
}

.DoorClosed {
    color: var(--color-alert);
    font-size: 2.2rem;

    &:hover {
        color: var(--color-dark-alert);
        cursor: pointer;
    }
}
