.AddPaymentModal {
    font-size: 1.6rem;
}

.Row {
    display: flex;
    &:not(:last-child) {
        margin-bottom: 10px;
    }
    align-items: center;

    .Label {
        flex: 1;
        text-align: end;
        margin-right: 10px;
        font-weight: bold;
    }

    .Content {
        flex: 1;
        justify-self: flex-start;
        margin-left: 10px;
    }
}

.Line {
    width: 100%;
    height: 1px;
    background-color: var(--color-grey);
    margin-bottom: 10px;
}

.ButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 5px;
}

.TableContainer {
    justify-content: center;
    display: flex;
}

.Header {
    font-weight: bold;
    padding-right: 10px;
    padding-bottom: 10px;
}

.Item {
    padding-left: 10px;
    padding-bottom: 10px;
}

.ButtonCell {
    padding-top: 10px;
}

.Label {
    font-weight: bold;
    margin-bottom: 4px;
    margin-left: 12px;
}

.InputContainer {
    margin-bottom: 16px;
}

.Remaining {
    color: var(--color-darker-grey);
    font-size: 1.4rem;
    margin-left: 24px;
    margin-top: 6px;
}

.Expense {
    font-size: 2.2rem;
    margin-bottom: 12px;
    margin-left: 6px;
}

.ExpenseLabel {
    font-weight: bold;
    font-size: 1.4rem;
}

.ExpenseName {
    margin-left: 4px;
}
