.TableTypeOption {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    padding: 10px;
    padding-left: 15px;

    &:hover {
        background-color: var(--color-light-grey);
        cursor: pointer;
    }
}

.Figure {
    width: 25%;
    display: flex;
    justify-content: center;
}

.Details {
    margin-left: 25px;
    font-size: 1.4rem;
    color: var(--color-darker-grey);
    width: 100%;
}

.NameContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Name {
    margin-right: 10px;
}

.ButtonContainer {
    display: flex;
    margin-left: 5px;

    & > *:not(:last-child) {
        margin-right: 5px;
    }
}

.Attribute {
    display: flex;
}

.Label {
    padding-right: 15px;
    font-weight: bold;
}

.Select {
    border: 0;
    border-bottom: 1px solid var(--color-dark-grey);
    background: transparent;
    color: var(--color-darker-grey);
    outline: 0px;
    width: 100%;
    &:focus,
    &:active,
    &:visited {
        border: 0px;

        border-bottom: 1px solid var(--color-primary);
    }
}
