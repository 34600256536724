.SectionHeaderInput {
    display: flex;
}

.ActionButtonContainer {
    display: flex;
    align-items: center;
    margin-left: 15px;
    & > *:not(:last-child) {
        margin-right: 5px;
    }
}
