.HostessView {
    background-color: var(--color-primary);
    color: white;
    padding: 2px 6px;
    font-size: 1.6rem;
    border-radius: 0px 0px 5px 5px;
}

.OccupancyPercentage {
    width: 135px;
    height: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: white;

    /* border: 3px solid var(--color-warning); */
    font-size: 5rem;
    margin-left: 15px;
    margin-bottom: 15px;
    box-shadow: var(--box-shadow);
}

.OccupancyStats {
    font-size: 1.8rem;
    background-color: white;
    box-shadow: var(--box-shadow);
    padding: 6px 12px;
    border-radius: 10px;
    margin-top: 5px;
    td {
        &:not(:last-child) {
            padding-right: 20px;
            color: var(--color-primary);
            font-weight: bold;
        }
    }
    tr:not(:last-child) {
        td {
            padding-bottom: 5px;
        }
    }
}

.ShowListButton {
    font-size: 1.6rem;
    color: white;
    background-color: var(--color-primary);
    padding: 2px 16px;
    border-radius: 25px;
}

@media (max-width: 1112px) {
    .OccupancyPercentage {
        width: 80px;
        height: 80px;
        /* border: 3px solid var(--color-warning); */
        font-size: 2.8rem;
        margin-left: 0px;
        margin-bottom: 0px;
    }

    .OccupancyStats {
        font-size: 1.6rem;
        td {
            &:not(:last-child) {
                color: var(--color-primary);
                font-weight: bold;
                padding-right: 20px;
            }
        }
        tr:not(:last-child) {
            td {
                padding-bottom: 5px;
            }
        }
    }
}

@media (max-width: 768px) {
    .OccupancyPercentage {
        /* border: 3px solid var(--color-warning); */
        font-size: 2.8rem;
    }

    .OccupancyStats {
        display: none;
    }
}
