.ChecklistItems {
    // overflow: auto;
    padding-right: 15px;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    &.Mobile {
        padding-right: 0px;
    }
}
