.GuestActionButtons {
    display: flex;
    align-items: center;
    align-content: center;
    justify-self: self-end;
    font-size: 1.6rem;
    justify-content: center;
    & > *:not(:last-child) {
        margin-right: 10px;
    }
}

.ActionButton {
    background-color: var(--color-dark-grey);
    width: 3rem;
    height: 3rem;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }

    &.Edit {
        background-color: #fff1e2;
        color: var(--color-warning);
        &:hover {
            background-color: var(--color-warning);
            color: white;
        }
    }

    &.Delete {
        background-color: #ffe7e5;
        color: var(--color-alert);
        &:hover {
            background-color: var(--color-alert);
            color: white;
        }
    }
    &.Save {
        background-color: #e8ffee;
        color: var(--color-success);
        &:hover {
            background-color: var(--color-success);
            color: white;
        }
    }
    &.Info {
        background-color: var(--color-light-grey);
        color: var(--color-muted-black);
        .Icon {
            transition: transform 250ms;
        }
        &.Open {
            background-color: var(--color-primary);
            color: white;

            .Icon {
                transform: rotate(90deg);
            }
        }
        &:hover:not(.Open) {
            background-color: var(--color-darker-grey);
            color: white;
        }
    }
}

.LoadingSpinner {
    justify-self: self-end;
}
