.QuickLinks {
    position: sticky;
    top: 78px;
    overflow: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
    align-self: flex-start;
    &.Mobile {
        position: fixed;
        right: 0;
        background-color: white;
        top: 0;
        bottom: 0;
        z-index: 1010;
        padding: 10px;
        border-left: 4px solid var(--color-primary);
        transform: translateX(calc(100% + 5px));
        transition: transform 200ms;
        &.Open {
            transform: translateX(0);
        }
    }
}

.Link {
    cursor: pointer;
    color: var(--color-darker-grey);
    &:not(:last-child) {
        margin-bottom: 5px;
    }
    &:hover {
        color: var(--color-primary);
    }
}

.Mobile {
    .Link {
        color: var(--color-muted-black);
    }
}
