.Header {
    display: flex;
    font-size: 3.6rem;
    align-items: center;
    margin-bottom: 10px;

    .Icon {
        margin-right: 15px;
    }

    &.Success {
        color: var(--color-success);
    }
    &.Alert {
        color: var(--color-alert);
    }
}

.Body {
    padding: 0px 20px;
    margin-bottom: 20px;
}

.ButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}

.LoadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .LoadingText {
        margin-top: 10px;
    }
}

.ImportInfo {
    margin-bottom: 10px;
}

.ColumnsTable {
    margin-top: 10px;
    border-collapse: collapse;
    margin-bottom: 10px;
    /* width: 100%; */
    th {
        text-align: left;
        padding: 5px;
    }

    td {
        padding: 10px;
        border: 1px solid var(--color-dark-grey);
        word-wrap: break-word;
        width: auto;
        text-align: center;
    }
}

.GuestsToImport,
.ColumnsDetected {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.NumberOfRows {
    color: var(--color-success);
    font-size: 4.2rem;
}

.Subtitle {
    font-weight: bold;
}

.ImportingInfo {
    display: flex;
    & > *:not(:last-child) {
        margin-right: 25px;
    }
}

.RowsMore {
    color: var(--color-alert);
    font-style: italic;
    margin-left: 15px;
}
