.GuideLinesInstructions {
    margin-bottom: 15px;
}

.GuideLinesList {
    padding-left: 35px;
    li {
        margin-bottom: 5px;
    }
}

.ColumnsTable {
    margin-top: 10px;
    border-collapse: collapse;
    margin-bottom: 20px;
    width: 100%;
    th {
        text-align: left;
        padding: 5px;
    }

    tbody {
        tr:first-child {
            td:first-child {
                background-color: var(--color-light-success);
            }
        }
    }

    td {
        padding: 10px;
        border: 1px solid var(--color-dark-grey);
        word-wrap: break-word;
        &:first-child {
            background-color: var(--color-light-warning);
        }
    }
}

.ImportButtonContainer {
    display: flex;
    justify-content: center;
}
