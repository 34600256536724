.Input {
    font-family: "Poppins";
    font-size: 1.6rem;
    border: 0px;
    border-bottom: 1px solid var(--color-dark-grey);
    padding: 2px;
    z-index: 35;
    position: relative;
    //border-radius: 10px;
    &:focus {
        outline: none;
        border-color: var(--color-primary);
    }
    &::placeholder {
        color: var(--color-dark-grey);
    }
}
