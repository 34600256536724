.NameInput {
    border: 0px;
    border-bottom: 1px solid var(--color-dark-grey);
    font-size: var(--font-size-text);
    font-family: "Poppins", sans-serif;
    outline: none;
    width: 100%;
    position: relative;
    z-index: 1;
    &:focus {
        border-bottom-color: var(--color-primary);
    }
}

.NameManager {
    font-size: var(--font-size-text);
    overflow: hidden;
}

.NameContainer {
    display: flex;
}

.AddedMessage {
    margin-right: 15px;
    color: var(--color-success);
    opacity: 0;
}

.AddedEnter {
    opacity: 0;
}

.AddedEnterActive {
    opacity: 1;
    transition: opacity 5000ms;
}

.AddedEnterDone {
    opacity: 1;
}

.AddedExit {
    opacity: 1;
}

.AddedExitActive {
    opacity: 0;
    transition: opacity 5000ms;
}

.AddedExitDone {
    opacity: 0;
}

.Name {
    border-bottom: 1px solid transparent;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.Label {
    font-weight: bold;
    font-size: 1.4rem;
    margin-bottom: 2px;
}

.MessageEnter {
    transform: translateY(-100%);
    opacity: 1 !important;
    //opacity: 0;
}

.MessageEnterActive {
    transform: translateY(0);
    transition: transform 500ms;
    //opacity: 1;
}

.MessageEnterDone {
    opacity: 1 !important;
}

.MessageExit {
    transform: translateY(0);
    opacity: 1 !important;
}

.MessageExitActive {
    transform: translateY(-100%);
    transition: transform 500ms;
    opacity: 1 !important;
}

.MessageExitDone {
    transform: translateY(-100%);
    //opacity: 0;
}
