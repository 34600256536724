.Expenses {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.Header {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.HeaderTitle {
    font-size: 2.2rem;
    color: var(--color-primary);
}

.NoItemsMessage {
    padding: 10px;
    font-style: italic;
    text-align: center;
    color: var(--color-dark-grey);
    margin-top: 15px;
}

.Total {
    color: white;
    padding: 15px;
    font-size: 3.6rem;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    background-color: var(--color-alert);
}

.ActionButton {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &:hover {
        background-color: var(--color-grey);
        cursor: pointer;
    }
    font-size: 2.2rem;
    color: var(--color-primary);
}
