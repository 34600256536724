.Container {
    padding: 25px;
}

.PlannerContainer {
    margin-bottom: 25px;
    .Label {
        font-weight: bold;
        margin-bottom: 4px;
    }

    .Value {
        margin-left: 10px;
    }
}
