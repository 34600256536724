.Button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    padding: 10px 20px;
    font-size: 1.6rem;
    background-color: var(--color-primary);
    color: white;
    border: 1px solid transparent;
    text-transform: uppercase;
    transition: all 0.2s;
    user-select: none;

    &:hover:not(.Loading) {
        cursor: pointer;
        background-color: var(--color-dark-primary);
    }

    &:hover {
        &.Disabled {
            cursor: not-allowed !important;
        }
        &.Loading {
            cursor: default !important;
        }
    }

    &:visited,
    &:focus {
        outline: none;
    }

    &.Disabled {
        background-color: var(--color-dark-grey);
        color: white;
        &:hover {
            background-color: var(--color-dark-grey);
            color: white;
        }
    }

    &.Success {
        background-color: var(--color-success);
        &:hover:not(.Loading) {
            background-color: var(--color-dark-success);
        }
    }

    &.Alert {
        background-color: var(--color-alert);
        &:hover:not(.Loading) {
            background-color: var(--color-dark-alert);
        }
    }

    &.Warning {
        background-color: var(--color-warning);
        &:hover:not(.Loading) {
            background-color: var(--color-dark-warning);
        }
    }

    &.Secondary {
        background-color: white;
        color: var(--color-primary);
        border-color: var(--color-primary);

        &:hover:not(.Loading) {
            color: white;
            background-color: var(--color-dark-primary);
        }

        &.Disabled {
            background-color: var(--color-dark-grey);
            color: white;
        }

        &.Success {
            color: var(--color-success);
            border-color: var(--color-success);
            &:hover:not(.Loading) {
                background-color: var(--color-dark-succes);
                color: white;
            }
        }

        &.Alert {
            color: var(--color-alert);
            border-color: var(--color-alert);
            &:hover:not(.Loading) {
                background-color: var(--color-dark-alert);
                color: white;
            }
        }
    }

    &.Tertiary {
        background-color: transparent;
        color: var(--color-primary);
        padding: 0 5px;

        &:hover:not(.Loading) {
            color: var(--color-dark-primary);
            background-color: transparent;
        }

        &.Disabled {
            color: var(--color-darker-grey);
            &:hover:not(.Loading) {
                color: var(--color-darker-grey);
            }
        }

        &.Success {
            background-color: transparent;
            color: var(--color-success);
            &:hover:not(.Loading) {
                color: var(--color-dark-success);
                background-color: transparent;
            }
        }

        &.Alert {
            background-color: transparent;
            color: var(--color-alert);
            &:hover:not(.Loading) {
                color: var(--color-dark-alert);
                background-color: transparent;
            }
        }

        &.Neutral {
            background-color: transparent;
            color: var(--color-muted-black);
        }
    }
}

.Inline {
    display: inline-flex;
}

.WholeWidth {
    display: flex;
    width: 100%;
}

.IconLeft {
    margin-right: 10px;
}

.IconRight {
    margin-left: 10px;
}

@media (max-width: 600px) {
    .FloatOnMobile {
        position: fixed;
        bottom: 15px;
        left: 15px;
        right: 15px;
        z-index: 50;
    }
}
