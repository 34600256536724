.IconContainer {
    position: relative;
    z-index: 10;
}

.IconButton {
    display: inline-flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: var(--color-primary);
    z-index: 45;
    -webkit-transform: translate3d(0, 0, 0);

    &:hover {
        cursor: pointer;
        &.Border {
            border: 1px solid transparent;
            &.MutedBlack {
                border-color: var(--color-muted-black);
            }
        }
    }
}

.ToolTipText {
    white-space: nowrap;
}

@media (max-width: 600px) {
    .FloatOnMobile {
        position: fixed;
        bottom: 15px;
        &.Right {
            right: 15px;
        }
        &.Left {
            left: 15px;
        }
    }
}
