.IdeaCardContainer {
    font-size: 1.6rem;
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);

    padding-top: 100%;
    position: relative;

    &.Pinned {
        border: 2px solid var(--color-primary);
        .Footer {
            .PinnedContainer {
                display: block;
            }
        }
    }

    &:not(.Pinned) {
        &:hover {
            .Footer {
                .PinContainer {
                    display: block;
                }
            }
        }
    }

    &:hover {
        .Icons {
            display: flex;
        }
    }
}

.IdeaCard {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 12px;
    display: flex;
    flex-direction: column;
}

.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .Title {
        font-size: 2.2rem;
        color: var(--color-primary);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .Icons {
        margin-left: 10px;
        display: none;
        & > :not(:last-child) {
            margin-right: 5px;
        }
    }
}

.Content {
    flex: 1;
    display: flex;
    justify-content: center;
    background-color: var(--color-light-grey);
    border-radius: 5px;
    margin: 10px 0px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Category {
        display: flex;
        align-items: center;
        padding: 1px 0px;
        .Color {
            height: 15px;
            width: 15px;
            border-radius: 5px;
            margin-right: 5px;
        }
    }

    .PinContainer {
        transform: rotate(45deg);
        display: none;
    }

    .PinnedContainer {
        margin-right: 8px;
        display: none;
        .PinIcon {
            color: var(--color-primary);
            cursor: pointer;
            transform: rotate(45deg);
        }
    }
}

@media (hover: none) {
    .Header {
        .Icons {
            display: flex;
        }
    }

    .IdeaCardContainer {
        &:not(.Pinned) {
            .Footer {
                .PinContainer {
                    display: block;
                }
            }
        }
    }
}
