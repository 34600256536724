.ActionButton {
    background-color: var(--color-dark-grey);
    width: 3rem;
    height: 3rem;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;

    &:hover {
        cursor: pointer;
    }

    &.Edit {
        background-color: #fff1e2;
        color: var(--color-warning);
        &:hover {
            background-color: var(--color-warning);
            color: white;
        }
    }

    &.Delete {
        background-color: #ffe7e5;
        color: var(--color-alert);
        &:hover {
            background-color: var(--color-alert);
            color: white;
        }
    }
    &.Save {
        background-color: #e8ffee;
        color: var(--color-success);
        &:hover {
            background-color: var(--color-success);
            color: white;
        }
    }
    &.More {
        background-color: var(--color-light-grey);
        color: var(--color-muted-black);

        &:hover {
            background-color: var(--color-darker-grey);
            color: white;
        }
    }

    &.View {
        background-color: var(--color-light-primary);
        color: var(--color-primary);

        &:hover {
            background-color: var(--color-dark-primary);
            color: white;
        }
    }
}
