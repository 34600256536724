.Dashboard {
    /* padding-right: 15px;
    padding-left: 15px; */
    margin-right: auto;
    margin-left: auto;

    display: grid;
    grid-template-columns: 1fr 4fr;
    column-gap: 4rem;
    margin-top: 2rem;
}

@media (max-width: 600px) {
    .Dashboard {
        grid-template-columns: 1fr;
    }
}
