.Calendar {
  display: inline-block;
  z-index: 1000;
}

.Header {
  font-size: 1.6rem;
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  user-select: none;
}

.Icon {
  font-size: 2.6rem;
  cursor: pointer;
}
