.ExtraInfo {
    position: absolute;
    right: -20px;
    top: -15px;
    z-index: 8;
}

.GuestOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 8;
    cursor: pointer;
    &.OnHovered {
        left: calc(100% + 15px);
        transform: translate(0px, -50%);
    }
}

.Guest {
    white-space: nowrap;
    text-align: center;
    background-color: var(--color-warning);
    &:not(:last-child) {
        margin-bottom: 2px;
    }
    font-size: 1.4rem;
    color: white;
    padding: 0px 6px;
    border-radius: 5px;
}

.RoundInfo {
    height: 25px;
    width: 25px;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary);
    color: white;
    border-radius: 50%;
}

.SquaredInfo {
    font-size: 1.4rem;

    color: var(--color-darker-grey);
    background: var(--color-warning);
    color: white;
    padding: 2px 6px;
    border-radius: 5px;
}
