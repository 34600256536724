.Home {
    font-size: 1.6rem;
}
.Card {
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 22px;
}

.TitleCard {
    font-weight: bold;
    color: var(--color-primary);
    font-size: 2.2rem;
    margin-bottom: 12px;
}

.ProgressBar {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: var(--color-grey);
    border-radius: 15px;
    //border: 1px solid var(--color-muted-black);
}

.Bar {
    position: absolute;
    background-color: var(--color-light-success);
    transition: width 1000ms;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 15px;
}

.ProgressText {
    padding: 5px 0px;
    z-index: 5;
    text-align: center;
    //color: white;
}

.Cards {
    display: flex;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-start;
    .CardsColumn {
        flex-basis: 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        &:not(:last-child) {
            margin-right: 25px;
        }
    }
}

.Card {
    margin-bottom: 25px;
}

.WeddingInformation {
    display: flex;
    flex-direction: column;

    .Name {
        display: flex;
        justify-content: center;

        color: var(--color-dark-primary);
        font-weight: bold;
        font-size: 2.6rem;
        //margin-bottom: 4px;
    }

    .Countdown {
        margin-bottom: 8px;
    }

    .Date {
        //font-style: italic;
        // font-size: 1.4rem;
        margin-bottom: 16px;
        text-align: center;
    }
}
