.SugestionsText {
    padding-left: 40px;
}

.UploadButtonContainer {
    display: flex;
    justify-content: center;
}
.LayoutExample {
    display: flex;
    justify-content: center;
    img {
        width: 300px;
    }
}
