.MainMenuIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    transition: transform 200ms;
    text-decoration: none;
    outline: none;
    color: black;
    position: relative;
    user-select: none;
    padding: 10px 25px;

    &:hover {
        .TooltipText {
            display: block;
        }
    }

    &:hover:not(.Active) {
        cursor: pointer;

        .Line {
            width: 100%;
        }
    }

    &.Active {
        border-bottom-color: var(--color-primary);
        .Line {
            width: 100%;
        }
    }

    .Icon {
        height: 3.2rem;
        margin-bottom: 0px;
        &.Active {
            filter: var(--navbar-icon-filter);
        }
    }

    .Text {
        text-align: center;
        font-size: 1.4rem;
    }

    .Line {
        height: 4px;
        background-color: var(--color-primary);
        position: absolute;
        width: 0px;
        bottom: 0;
        transition: width 100ms;
    }

    &.Mobile {
        padding: 12px 15px;
        padding-left: 11px;

        &:hover:not(.Active) {
            cursor: pointer;
            border-left-color: var(--color-primary);
            // transform: scale(1.3);
        }

        &.Active {
            border-left-color: var(--color-primary);
            .Line {
                height: 100%;
            }
        }

        .Line {
            width: 4px !important;
            left: 0;
            bottom: initial;
            height: 0;
        }
    }
}

@media (max-width: 724px) {
    .MainMenuIcon {
        padding: 15px 10px;
        padding-bottom: 5px;
        justify-content: space-around;
    }
}

.FaIcon {
    font-size: 3.4rem;
    color: var(--color-muted-black);
    &.Active {
        color: var(--color-primary);
    }
}

.TooltipText {
    position: absolute;
    font-size: 1.2rem;
    color: white;
    background-color: var(--color-primary);
    transform: translate(-50%, 7px);
    left: 50%;
    padding: 2px 5px;
    border-radius: 5px;
    display: none;
    &:after,
    &:before {
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
    }

    &:after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid var(--color-primary);
    }
}

@media (max-width: 924px) {
    .MainMenuIcon:not(.Mobile) {
        padding: 10px 15px;
        display: block;
    }
}

@media (max-width: 765px) {
    .MainMenuIcon:not(.Mobile) {
        padding: 10px 5px;
        display: block;
    }
}
