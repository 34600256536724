.GuestStats {
    font-size: 1.6rem;
}

.Filter {
    display: flex;
    align-items: center;
    &:nth-child(2) {
        margin-left: 25px;
    }
    &:nth-child(3) {
        margin-left: 50px;
    }
    &:nth-child(4) {
        margin-left: 75px;
    }
    &:nth-child(5) {
        margin-left: 100px;
    }
    &:not(:last-child) {
        margin-bottom: 10px;
    }
}

.FilterLabel {
    margin-right: 10px;
}

.FilterSection {
    background-color: var(--color-light-grey);
    padding: 15px;
    //margin-bottom: 15px;
}

.ResultsSection {
    padding: 25px;
    padding-top: 10px;
    overflow: auto;
}
