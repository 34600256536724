.TableConfiguration {
    font-size: 1.6rem;
}

.Input {
    width: 100%;
    border: 0px;
    border-bottom: 1px solid var(--color-dark-grey);
    background-color: transparent;
    outline: none;
    position: relative;
    z-index: 1;
    font-size: 1.6rem;
    font-family: "Poppins";
}

.Select {
    border: 0px;
    border-bottom: 1px solid var(--color-dark-grey);
    font-size: 1.6rem;
    font-family: "Poppins";
    width: 100%;
}

.TypeName {
    // font-weight: bold;
    background-color: var(--color-primary);
    text-align: center;
    color: white;
    display: inline-block;
    padding: 2px 6px;
    border-radius: 5px;
    font-size: 1.4rem;
}

.CheckboxContainer {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    margin-top: 5px;

    & > *:not(:last-child) {
        margin-right: 5px;
        flex-shrink: 0;
    }
}

.Label {
    padding-right: 10px;
    padding-bottom: 2px;
    font-weight: bold;
}

.ButtonContainer {
    display: flex;
    justify-content: flex-end;

    & > div:not(:last-child) {
        margin-right: 5px;
    }
}
