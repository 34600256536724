.TimeManager {
    &.Medium {
        .Label {
            font-size: 1.6rem;
        }
        .CalculatedTime {
            font-size: 1.4rem;
        }
    }
}

.SelectOptions {
    display: flex;
    align-items: center;
    & > :not(:last-child) {
        margin-right: 6px;
    }
    justify-content: center;
}

.Label {
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 4px;
}

.CalculatedTime {
    font-size: 1.2rem;
    text-align: center;
    margin-top: 4px;
    color: var(--color-darker-grey);
}
