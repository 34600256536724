.ValueContainer {
    display: flex;
    align-items: center;
}

.EditContainer {
    margin-left: 10px;
    position: relative;
}

.NoValue {
    /* padding-top: 29px;
    height: 1px;
    border-bottom: 1px solid var(--color-darker-grey);
    max-width: 300px;
    flex: 1; */
    padding: 2px 8px;
    background-color: var(--color-warning);
    border-radius: 5px;
    color: white;
}

.Value {
    padding: 2.5px 0px;
    white-space: pre-line;
}

.Location {
    display: flex;
    align-items: center;
    .Marker {
        font-size: 2.2rem;
        margin-right: 10px;
        color: var(--color-primary);
    }
}

.Link {
    color: var(--color-primary);
    cursor: pointer;
    text-decoration: underline;
}

.Duration {
    color: var(--color-darker-grey);
    margin-left: 5px;
}

.Read {
    margin-left: 5px;
}
