.RoundInfo {
    height: 25px;
    width: 25px;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary);
    color: white;
    border-radius: 50%;
}

.SquaredInfo {
    font-size: 1.4rem;

    color: var(--color-darker-grey);
    background: var(--color-warning);
    color: white;
    padding: 2px 6px;
    border-radius: 5px;
}

.ExtraInfo {
    position: absolute;
    right: -20px;
    top: -15px;
    z-index: 15;
}
