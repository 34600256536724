.EditButtonContainer {
    display: flex;
    flex-flow: column;
    position: absolute;
    top: -15px;
    left: calc(100% + 5px);
    z-index: 9;
}

.EditableTable {
    position: absolute;
    /* top: 0;
    left: 0; */
}
