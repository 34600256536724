.Spinner {
  animation: rotation 1.5s infinite linear;
  display: inline-block;
  color: var(--color-primary);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes rotation {
  to {
    transform: translate(-50%, -50%) rotateY(360deg);
  }
}
