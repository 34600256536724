.Label {
    font-weight: bold;
    margin-bottom: 2px;
    font-size: 1.4rem;
}

.InvitedByManager {
    font-size: var(--font-size-text);
    /*  width: 16rem; */
}
