.Picker {
    border: 2px solid var(--color-primary);
    display: inline-flex;
    border-radius: 5px;
    align-items: center;
    padding: 16px;
    background-color: white;
    z-index: 1200;
}

.TimeSeparator {
    font-size: 4.6rem;
    margin: 0px 10px;
}

.AmPmSelector {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > *:not(:last-child) {
        margin-bottom: 10px;
    }

    & > * {
        padding: 4px;
        border-radius: 5px;
        cursor: pointer;
        &.Selected {
            background-color: var(--color-primary);
            color: white;
        }
    }
}
