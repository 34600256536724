.QuickLinks {
    font-size: 1.6rem;
    position: sticky;
    top: 82px;
    & > * {
        display: flex;
        align-items: center;
        color: var(--color-darker-grey);
        cursor: pointer;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        &:hover {
            color: var(--color-primary);
        }
    }
    .Active {
        color: var(--color-primary);
    }
    overflow: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    &.Mobile {
        position: fixed;
        right: 0;
        background-color: white;
        top: 0;
        bottom: 0;
        z-index: 1010;
        padding: 10px;
        border-left: 4px solid var(--color-primary);
        transform: translateX(100%);
        transition: transform 200ms;
        &.Open {
            transform: translateX(0);
        }
    }
}

.Dot {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: var(--color-primary);
    margin-right: 4px;
}
