.StatusColumn {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.Active {
        color: var(--color-success);
    }

    &.Pending {
        color: var(--color-warning);
    }

    &.Blocked {
        color: var(--color-alert);
    }

    & .Icon {
        margin-right: 5px;
    }
}

.EnvelopeIcon {
    color: var(--color-muted-black);
    cursor: pointer;
    &:hover + .TooltipContainer {
        display: block;
    }
}

.EnvelopeContainer {
    position: relative;
    margin-left: 8px;
}

.TooltipContainer {
    display: none;
}

.SendActivationButton {
    display: flex;
    align-items: center;
    color: var(--color-primary);
    padding: 4px 8px;
    background-color: var(--color-lighter-grey);
    border-radius: 5px;

    &.Button {
        cursor: pointer;
        &:hover {
            color: white;
            background-color: var(--color-primary);
        }
    }

    .SendIcon {
        margin-right: 5px;
    }
}
