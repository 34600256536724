.TopBar {
    background-color: #f3f8ff;
}

.Icons {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0px 40px;
}

.Text {
    text-align: center;
    font-size: 1.4rem;
}

.Progression {
    width: 100%;
    height: 2px;
    background-color: var(--color-dark-grey);
}

.WeddingContent {
    padding-top: 71px;
    height: 100%;
}

@media (min-width: 768px) {
    .Icons {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .Icons {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .Icons {
        width: 1170px;
    }
}

@media (min-width: 1400px) {
    .Icons {
        width: 1380px;
    }
}
