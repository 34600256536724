.CompanionsManager {
    justify-self: center;
    font-size: 1.6rem;
    display: inline-flex;
}

.Stepper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-text);

    & > *:not(:last-child) {
        margin-right: 15px;
    }
}

.Icon {
    &:hover {
        color: var(--color-primary);
        cursor: pointer;
    }
}

.Value {
    user-select: none;
}

.MinusContainer {
    position: relative;
}

.UpdateConfirmationText {
    text-align: center;
}

.UpdateConfirmationButtons {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    & > div {
        &:hover {
            cursor: pointer;
        }
        &:not(:last-child) {
            margin-right: 15px;
        }
    }
}
