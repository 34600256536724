.FormContainer {
    position: absolute;
    top: 20%;
    left: 50%;
    width: 40rem;
    transform: translate(-50%, -20%);
    z-index: 10;
}

.ContentContainer {
    padding: 3rem;
    padding-top: 6rem;
    border-radius: 10px;
    border: 1px solid #e9e9e9;
    background-color: white;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    animation: AuthAppear 0.6s ease-out;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.Logo {
    width: 10rem;
    height: 10rem;
    display: block;
    transform: translateY(50%);
    z-index: 2;
    border-radius: var(--main-logo-border-radius);
}

.LogoContainer {
    display: flex;
    justify-content: center;
}

.Title {
    font-size: 2.2rem;
    margin-bottom: 0.5rem;
}

.Text {
    font-size: 1.4rem;
}

@media only screen and (max-width: 456px) {
    .FormContainer {
        width: 90%;
    }
}

.StatusEnter {
    transform: translateY(-100%);
}

.StatusEnterActive {
    transform: translateY(0) !important;
    transition: transform 500ms;
}

.StatusEnterDone {
    transform: translateY(0) !important;
}

.StatusExit {
    transform: translateY(0) !important;
}

.StatusExitActive {
    transform: translateY(-100%) !important;
    transition: transform 500ms;
}
