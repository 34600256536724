.SidebarItem {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 1.6rem;
    border-radius: 5px;
    text-decoration: none;
    color: black;

    &:hover {
        cursor: pointer;
        background-color: var(--color-light-grey);
    }

    &.Active {
        background-color: var(--color-primary);
        color: white;

        .Icon {
            color: white;
        }
    }
}

.Icon {
    margin-right: 15px;
    color: var(--color-muted-black);
}

@media (max-width: 600px) {
    .SidebarItem {
        border-radius: 0px;
        border-left: 4px solid transparent;

        &.Active {
            background-color: transparent;
            color: var(--color-primary);
            border-left-color: var(--color-primary);
            .Icon {
                color: var(--color-primary);
            }
        }
    }
}
