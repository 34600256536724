$chairSize: 15px;

.Backrest {
    border: 1px solid black;
    border-radius: $chairSize / 2;
    background-color: white;
}

.Seat {
    border: 1px solid black;
    background-color: white;
    //margin-top: -2px;
    z-index: 5;
}

.Chair {
    display: inline-flex;
    flex-flow: column;
    align-items: center;
    position: absolute;
}
