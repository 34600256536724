.Title {
    flex: 1 1 60%;
    /* overflow: hidden; */
}

.Value {
    flex: 1 0 60%;
}

.Element {
    display: flex;
    /* flex-direction: column; */
    /*   align-items: flex-start; */
    align-items: center;
    &:not(:last-child) {
        border-bottom: 1px solid var(--color-grey);
    }
    padding: 12px 0px;
    &:first-child {
        padding-top: 0px;
    }
    &.TwoRows:not(.EditMode) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.EditMode {
    padding: 6px 14px;
    background-color: white;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 10px;
    border-radius: 5px;
    &:not(:last-child) {
        border-bottom: none;
    }
    &:first-child {
        padding-top: 6px;
    }
}
