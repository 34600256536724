.ProfileButton {
    padding: 0.8rem 1.5rem;
    background-color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    height: 4.5rem;
    width: 4.5rem;
    color: white;
    user-select: none;

    & > *:not(:last-child) {
        margin-right: 1rem;
    }

    &:hover {
        cursor: pointer;
    }

    &.ReducedView {
        border-radius: 100px;
        padding: 0.75rem;

        & > * {
            margin-right: 0px !important;
        }
    }
}

.Name {
    font-size: 1.8rem;
    //color: white;
}

.Caret {
    //color: white;
    font-size: 2.6rem;
}

.ProfileImage {
    height: 3rem;
}

.ProfileIcon {
    font-size: 2.2rem;
}

.ProfileContainer {
    position: relative;
}

.Options {
    position: absolute;
    width: 100%;
    background-color: white;
    padding: 0.5rem 0rem;
    border-radius: 10px;
    border: 1px solid var(--color-primary);
    margin-top: 5px;
    max-height: 100px;
    transition: all 0.2s;
    overflow: hidden;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
    z-index: 10;
}

.Options.Collapsed {
    max-height: 0;
    padding: 0;
    //opacity: 0;
}

.Enter {
    max-height: 0;
    padding: 0;
}

.EnterActive,
.EnterDone {
    max-height: 100px;
    padding: 0.5rem 0rem;
}

.ExitActive,
.ExitDone {
    max-height: 0;
    padding: 0;
}

@media only screen and (max-width: 600px) {
    .Options {
        width: 80%;
        border-radius: 0px;
        position: fixed;
        margin-top: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1010;

        & > * {
            font-size: 2rem !important;
        }
    }
}
