.Row {
    display: flex;
    align-items: center;
}

.Title {
    font-weight: bold;
    flex: 1 1 50%;
    align-self: flex-start;
    font-size: 1.6rem;
}

.Info {
    flex: 1 1 50%;
    text-align: end;
    font-size: 1.6rem;
    overflow-wrap: anywhere;
}

.SelectSupplier {
    text-decoration: underline;
    color: var(--color-primary);
    cursor: pointer;
}
.Line {
    width: 100%;
    height: 1px;
    background-color: var(--color-grey);
    margin: 15px 0px;
}

.TextArea {
    border-color: var(--color-dark-grey);
    width: 100%;
    font-size: 1.6rem;
    font-family: "Poppins";
    padding: 4px;
    border-radius: 5px;
    &:focus {
        outline: none !important;
        border: 1px solid var(--color-primary);
    }
}

.SubSection {
    margin-bottom: 10px;
}

.Payment {
    display: flex;
    font-size: 1.6rem;
    margin-left: 8px;
    justify-content: space-between;
}

.PaymentDate {
    color: var(--color-darker-grey);
    margin-right: 10px;
}

.SupplierData {
    display: flex;
    justify-content: space-between;
}

.SuplierInfo {
    margin-left: 10px;
}

.InfoLine {
    display: flex;
    justify-content: space-between;
}

.Header {
    font-weight: bold;
}

.PaymentHeader {
    display: flex;
}

.PaymentList {
    margin-bottom: 5px;
}

.RemoveSupplier {
    color: var(--color-alert);
    font-size: 1.4rem;
    text-decoration: underline;
    cursor: pointer;
}

.RemoveSupplierContainer {
    display: flex;
    justify-content: flex-end;
}
