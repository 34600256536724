.ContextSelect {
    position: relative;
    //z-index: 5;
}

.SelectedOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-text);
    color: black;
    justify-self: center;
    padding: 2px 12px;
    border-radius: 15px;
    position: relative;
    background-color: white;
    border: 1px solid transparent;
    &:hover:not(.Locked) {
        cursor: pointer;
    }
}

.Value {
    white-space: pre;
    text-overflow: ellipsis;
    overflow-x: hidden;
    user-select: none;
    margin-right: 10px;
}

.Icon {
    margin-right: 5px;
}

.JustColor {
    width: 18px;
    height: 18px;
    padding: unset;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow-x: unset;
    background-color: var(--color-light-grey);
}

.Locked {
    justify-content: center;
}

.ActionButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Label {
    position: absolute;
    top: 5px;
    margin-left: 17px;
    z-index: 5;
    font-weight: bold;

    &.LabelUp {
        top: -14px;
        font-size: 12px;
        opacity: 1;
    }

    &.Enter {
        top: 5px;
        font-size: 1.6rem;
    }

    &.EnterActive {
        top: -14px;
        font-size: 12px;
        transition: all 250ms;
    }

    &.EnterDone {
        top: -14px;
        font-size: 12px;
        opacity: 1;
    }

    &.Exit {
        top: -14px;
        font-size: 12px;
        opacity: 1;
    }

    &.ExitActive {
        top: 5px;
        font-size: 1.6rem;
        transition: all 250ms;
    }
}
