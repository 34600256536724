.ExpenseItemContainer {
    /* padding: 0px 10px; */
    border-bottom: 1px solid var(--color-grey);
    &:hover:not(.EditMode) {
        cursor: pointer;
        background-color: var(--color-light-grey);
    }
    padding: 12px 10px;
    position: relative;
}

.ExpenseItem {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
}

.Text {
    flex-grow: 1;
    padding: 4px 0px;
    overflow: hidden;
}

.Amount {
    padding-left: 15px;
}

.ActionButtons {
    margin-left: 15px;
    display: flex;
    & > *:not(:last-child) {
        margin-right: 5px;
    }
    &.Mobile {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.4);
        align-items: center;
        justify-content: center;
        margin: 0;
        & > *:not(:last-child) {
            margin-right: 15px;
        }
    }
}
