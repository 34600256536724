.Budget {
    margin-top: var(--margin-top-container);
    font-size: 1.6rem;
}

.Content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 25px;
    &.Mobile {
        grid-template-columns: 1fr;
        & > *:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

.Header {
    font-size: 2.2rem;
    color: var(--color-primary);
    margin-bottom: 5px;
}

.Dashboard {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.Box {
    flex-grow: 1;
    background-color: white;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.List {
    flex-grow: 1;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-track {
        background: hsla(0, 0%, 100%, 0.1);
    }
    scrollbar-color: rgba(0, 0, 0, 0.2) rgba(255, 255, 255, 0.1);
    scrollbar-width: thin;
}

.Total {
    background-color: var(--color-alert);
    color: white;
    padding: 15px;
    font-size: 3.6rem;
    display: flex;
    justify-content: center;
    border-radius: 0px 0px 10px 10px;
}

.SearchBar {
    background-color: var(--color-grey);
    padding: 15px 5px;
    border-radius: 10px 10px 0px 0px;
}

.TitleContainer {
    display: flex;
    justify-content: space-between;
}

.Progress {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: var(--color-grey);
    border-radius: 15px;
    //border: 1px solid var(--color-muted-black);
    margin-bottom: 10px;
}

.ProgressBar {
    position: absolute;
    background-color: var(--color-light-success);
    transition: width 1000ms;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 15px;
}

.ProgressText {
    padding: 5px 0px;
    z-index: 5;
    text-align: center;
    //color: white;
}

.SuppliersButton {
    position: fixed;
    bottom: 15px;
    right: 15px;
}

.MobileModal {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 35;
    transform: translateY(calc(100% + 15px));
    transition: transform 300ms;
    display: flex;
    flex-direction: column;
    background-color: white;

    &.Show {
        transform: translateY(0);
    }

    .Total,
    .SearchBar {
        border-radius: 0;
    }
}

.MobileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 2.6rem;
    padding: 12px;
    background-color: var(--color-primary);
}
