.Headers {
    font-weight: bold;
    display: flex;
}

.NameColumn {
    flex: 1 1 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 15px;
}

.BalanceColumn {
    flex: 1 1 50%;
    display: flex;
    justify-content: center;
}

.ActionButtons {
    display: flex;
    padding-right: 20px;
}
