.TableIndicator {
    position: relative;
}

.Table {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 1px solid var(--color-warning);
    user-select: none;

    &.Small {
        font-size: 1.2rem;
        height: 22px;
        width: 22px;
    }
}
