.Persistent {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
}

.RSVPView {
    display: flex;
    justify-content: center;
    align-items: center;
    .Icon {
        font-size: 2.6rem;
        &:not(:last-child) {
            margin-right: 15px;
        }
        cursor: pointer;
        opacity: 0.2;
        &.Selected {
            opacity: 1;
        }
    }
}

.Icon {
    margin-right: 5px;
}

.OptionIcons {
    font-size: 2.4rem;
    &:not(:last-child) {
        margin-right: 10px;
    }
}

.StatusManager {
    justify-self: center;
    font-size: 1.6rem;
    width: 16rem;
}

.Times {
    color: var(--color-alert);
    cursor: pointer;
}

@media (max-width: 600px) {
    .Persistent {
        justify-content: start;
    }
}
