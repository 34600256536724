.InputWrapper {
    display: flex;
    align-items: center;
    &:first-child {
        flex: 1;
    }
}

.InputContainer {
    flex: 1;
}

.LocationContainer {
    display: flex;
    align-items: center;
    flex: 1;
}

.MapMarker {
    font-size: 2.2rem;
    margin-right: 10px;
    color: var(--color-darker-grey);
    &.Active {
        color: var(--color-success);
    }
}

.ActionButtonContainer {
    display: flex;
    align-items: center;
    margin-left: 15px;
    & > *:not(:last-child) {
        margin-right: 5px;
    }
}

.TimeFrame {
    display: flex;

    & > *:not(:last-child) {
        margin-right: 10px;
    }
}
