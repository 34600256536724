.MainMenu {
    flex-grow: 1;
    background-color: transparent;

    &.Mobile {
        display: inline-block;
        position: fixed;
        top: 0;
        bottom: 0;
        padding: 0px 0px;
        transform: translateX(-100%);
        transition: transform 200ms;
        z-index: 1050;
        overflow-y: auto;
        background-color: white;

        &.Open {
            transform: translateX(0);
        }

        .Icons {
            flex-flow: column;
            display: inline-flex;
            min-height: 100%;
            min-height: -webkit-fill-available;
            padding: 0;
            //align-items: initial;
        }
    }
}

.Icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0px 40px;
    justify-content: center;
}

@media (max-width: 767px) {
    .MainMenu {
        flex-grow: 1;
    }
    .Icons {
        padding: 0px 25px;
        justify-content: space-between;
    }
}
