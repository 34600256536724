.Credits {
    font-size: 1.6rem;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.BalanceDescription {
    margin-bottom: 5px;
}

.CurrentBalance {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 35px;
}

.BalanceSubdescription {
    text-align: center;
    color: white;
    font-style: italic;
    font-size: 1.4rem;
}

.CreditQuanityContainer {
    background: rgb(40, 167, 69);
    background: linear-gradient(
        160deg,
        rgba(40, 167, 69, 1) 33%,
        rgba(12, 114, 118, 1) 66%
    );

    background: linear-gradient(
        to bottom right,
        var(--color-success),
        var(--color-primary)
    );

    /*  background: var(--color-success); */

    border-radius: 10px;
    padding: 8px 24px;
}

.CreditQuantity {
    font-size: 4rem;

    display: inline-flex;
    color: white;

    align-items: center;
    margin: 16px 0px;

    .Number {
        font-size: 9rem;
        margin-right: 12px;
        line-height: 7.2rem;
    }

    .Text {
        font-size: 5rem;
        line-height: 4.2rem;
        align-self: flex-end;
    }
}

.NoTransactionsMessage {
    margin-top: 10px;
}
