.Option {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    & > *:not(:last-child) {
        margin-right: 10px;
    }
}

.OptionExplanation {
    font-size: 1.4rem;
    color: var(--color-darker-grey);
    font-style: italic;
}
