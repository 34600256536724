.IdeaBoard {
    margin-top: var(--margin-top-container);
}

.Board {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    //grid-auto-columns: minmax(250px, auto);
    gap: 15px;
    padding-bottom: 25px;
}

.AddingSquare {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--color-primary);
    border: 2px dashed var(--color-primary);
    border-radius: 5px;
}

.PlusIcon {
    font-size: 4.2rem;
    margin-bottom: 15px;
}

.AddingText {
    font-size: 2.2rem;
    text-align: center;
}
.Header {
    display: flex;
    justify-content: space-between;
}

.MobileAddButton {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 100;
}
