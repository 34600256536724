.SectionHeader {
    font-size: 2rem;
    font-weight: bold;
    color: var(--color-primary);
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.BarsIcon {
    margin-right: 10px;
    cursor: grab;
}

.ActionButtonContainer {
    display: flex;
    align-items: center;
    margin-left: 15px;
    & > *:not(:last-child) {
        margin-right: 5px;
    }
}

.Caret {
    font-size: 2.4rem;
    margin-right: 8px;
    transform: rotate(90deg);
    transition: transform 200ms;
    cursor: pointer;
    &.IsCollapsed {
        transform: rotate(0deg);
    }
}
