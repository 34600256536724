$duration: 500ms;

.Notification {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    position: fixed;
    top: 15px;
    z-index: 10000;
    font-size: var(--font-size-text);
    padding: 10px 20px;
    border-radius: 10px;
    left: 50%;
    transform: translate(-50%, -200%);
    max-width: 1280px;
    box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
        rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;

    &.Info {
        background-color: var(--color-primary);
    }

    &.Success {
        background-color: var(--color-success);
    }

    &.Alert {
        background-color: var(--color-alert);
    }

    &.Warning {
        background-color: var(--color-warning);
    }
}

.Message {
    display: flex;
    align-items: center;
}

.Icon {
    margin-right: 15px;
    font-size: 2.2rem;
}

.Close {
    font-size: 2.2rem;
    margin-left: 15px;
    cursor: pointer;
}

/* .Text {
    overflow: hidden;
    white-space: nowrap;
    max-width: 0;
} */

.EnterActive {
    transform: translate(-50%, 0);
    /*     & .Text {
        max-width: 1280px;
        transition: max-width $duration $duration;
    } */
    transition: transform $duration;
}

.EnterDone {
    transform: translate(-50%, 0);
    /*  & .Text {
        max-width: 1280px;
    } */
}

.Exit {
    transform: translate(-50%, 0);
    /*     & .Text {
        max-width: 1280px;
    } */
}

.ExitActive {
    transform: translate(-50%, -200%);
    /*     & .Text {
        max-width: 0;
        transition: max-width $duration;
    } */
    transition: transform $duration;
}

@media (max-width: 1400px) {
    .Notification {
        max-width: 860px;
    }
}

@media (max-width: 1200px) {
    .Notification {
        max-width: 860px;
    }
}

@media (max-width: 992px) {
    .Notification {
        max-width: 620px;
    }
}

@media (max-width: 768px) {
    .Notification {
        left: 15px;
        right: 15px;
        transform: translate(0, -200%);
    }

    .EnterActive {
        transform: translate(0, 0);
        /*     & .Text {
            max-width: 1280px;
            transition: max-width $duration $duration;
        } */
        transition: transform $duration;
    }

    .EnterDone {
        transform: translate(0, 0);
    }

    .Exit {
        transform: translate(0, 0);
        /*     & .Text {
            max-width: 1280px;
        } */
    }

    .ExitActive {
        transform: translate(0, -200%);
        /*     & .Text {
            max-width: 0;
            transition: max-width $duration;
        } */
        transition: transform $duration;
    }
}
