.CategorySelect {
    position: relative;
}

.SelectedOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 100px;
    border: 1px solid #e9e9e9;
    font-size: var(--font-size-text);
    padding: 10px 20px;
    position: relative;
    z-index: 2;
    font-family: "Poppins";
    cursor: pointer;

    .ArrowIcon {
        font-size: 2.4rem;
        color: var(--color-dark-grey);
    }

    .SelectedCategory {
        display: flex;
        align-items: center;
        .Color {
            height: 16px;
            width: 16px;
            border-radius: 3px;
            margin-right: 10px;
        }
    }
}
