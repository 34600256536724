.PageOverlay {
    position: fixed;
    top: 25px;
    bottom: 25px;
    width: 80rem;
    max-width: 100%;
    z-index: 1010;
    box-shadow: var(--box-shadow);
    opacity: 0;
    background-color: white;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    &.EnterActive {
        opacity: 1;
        transition: opacity 250ms;
    }

    &.EnterDone {
        opacity: 1;
    }

    &.Exit {
        opacity: 1;
    }

    &.ExitActive {
        transition: opacity 250ms;
        opacity: 0;
    }
}

.Content {
    flex: 1;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-track {
        background: hsla(0, 0%, 100%, 0.1);
    }
    scrollbar-color: rgba(0, 0, 0, 0.2) rgba(255, 255, 255, 0.1);
    scrollbar-width: thin;
}

.Title {
    display: flex;
    align-items: center;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2.8rem;
    color: white;
    background-color: var(--color-primary);

    padding: 10px 15px;
}

.Icon {
    margin-right: 15px;
}

.TimesIcon {
    cursor: pointer;
}

@media (max-width: 600px) {
    .PageOverlay {
        top: 0;
        bottom: 0;
    }
}
