.PageHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Guests {
    margin-top: var(--margin-top-container);
}

@media (max-width: 600px) {
    .PageHeader {
        justify-content: center;
    }

    /* .FilteredNumberContainer {
        display: none;
    } */
}
