.NavBar {
    width: 100%;
    //height: 6.5rem;
    //background-color: #f8fff4;
    background-color: white;
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 35;

    &.IsLocked {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
}

.Logo {
    padding: var(--navbar-logo-padding);
    width: var(--navbar-logo-width);
    border-radius: var(--navbar-logo-border-radius);
    cursor: pointer;
}

.LogoIcon {
    height: 5rem;
}

.Profile {
    padding: 0.8rem 1.5rem;
    background-color: var(--color-primary);
    display: flex;
    align-items: center;
    border-radius: 10px;

    & > *:not(:last-child) {
        margin-right: 1rem;
    }
}

.Name {
    font-size: 1.8rem;
    color: white;
}

.Caret {
    color: white;
    font-size: 2.6rem;
}

.ProfileIcon {
    width: 3rem;
}

.Content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.Bars {
    color: var(--color-primary);
    font-size: 2.6rem;
}

.LogoContainer {
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
}

.Pipe {
    width: 2px;
    height: 25px;
    margin: 0px 20px;
    background-color: var(--color-primary);
}

.WeddingName {
    font-size: 2.2rem;
    color: var(--color-muted-black);
}

/* @media (max-width: 1400px) {
    .Content {
        width: 1156px;
    }
}

@media (max-width: 1200px) {
    .Content {
        width: 960px;
    }
}

@media (max-width: 992px) {
    .Content {
        width: 720px;
    }
}

@media (max-width: 768px) {
    .Content {
        width: 100%;
    }
}

//Mobile phones
@media (max-width: 600px) {
    .Content {
        width: 100%;
    }
} */
