.Checklist {
    margin-top: var(--margin-top-container);
}

.Header {
    display: flex;
    justify-content: space-between;
}

.ChecklistContent {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 35px;
    // overflow: auto;

    &.Mobile {
        grid-template-columns: 1fr;
    }
}

.MobileButtons {
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 25;
    & > *:not(:last-child) {
        margin-bottom: 5px;
    }
}
