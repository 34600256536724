.Visor {
    /* max-width: 100%; */
    padding: 0 15px;
    //min-width: 1380px;
    overflow: auto;
    width: 100%;
    touch-action: none;
}

.VisorContainer {
    position: relative;

    overflow: hidden;
}

.CanScroll {
    cursor: all-scroll;
}

/* .Layout {
    width: 1380px;
    margin: 0 auto;
    transition: width 100ms;
    user-select: none;
    position: relative;
} */

.ZoomDisplay {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2rem;
    padding: 4px 22px;
    border-radius: 50px;
    background: rgba(95, 99, 104, 0.8);
    color: white;
    user-select: none;
    display: none;
    z-index: 10;

    &.Enter {
        display: block;
        opacity: 0;
    }

    &.EnterActive {
        opacity: 1;
        display: block;
        transition: opacity 250ms;
    }

    &.EnterDone {
        display: block;
        opacity: 1;
    }

    &.Exit {
        opacity: 1;
        display: block;
    }

    &.ExitActive {
        opacity: 0;
        display: block;
        transition: opacity 250ms;
    }
}

.Options {
    display: flex;
    & > *:not(:first-child) {
        margin-left: 5px;
    }
}

.OptionColumn {
    display: flex;
    flex-direction: column;
    & > :not(:last-child) {
        margin-bottom: 5px;
    }
}

.TooltipText {
    white-space: nowrap;
}

.BottomRight,
.BottomLeft,
.TopRight,
.TopLeft,
.TopCenter,
.Bottom {
    position: absolute;
    z-index: 20;
}

.TopRight {
    z-index: unset;
}

$sidesRegular: 350px;
$sidesMedium: 200px;

.BottomRight {
    right: $sidesRegular;
    bottom: 10px;
}

.Bottom {
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.TopCenter {
    top: 10px;
    transform: translateY(calc(-100% - 20px));
    left: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    &.EnterActive {
        transition: transform 250ms;
        transform: translateY(0);
    }

    &.EnterDone {
        transform: translateY(0);
    }

    &.Exit {
        transform: translateY(0);
    }

    &.ExitActive {
        transform: translateY(calc(-100% - 20px));
        transition: transform 250ms;
    }
}

.BottomLeft {
    bottom: 10px;
    /* left: 50%;
    transform: translate(-50%, calc(100% + 10px));
 */
    /*     &.Enter {
        display: block;
        opacity: 0;
    } */

    /* &.EnterActive {
        transition: transform 250ms;
        transform: translate(-50%, 0);
    }

    &.EnterDone {
        transform: translate(-50%, 0);
    }

    &.Exit {
        transform: translate(-50%, 0);
    }

    &.ExitActive {
        transform: translate(-50%, calc(100% + 10px));
        transition: transform 250ms;
    } */
}

.TopLeft {
    top: 10px;
    //left: 50%;
    //transform: translateX(-50%);
}

.TopRight {
    top: 10px;
    right: $sidesRegular;
}

/* @media (max-width: 1400px) {
    .Layout {
        width: 1156px;
    }
}

@media (max-width: 1200px) {
    .Layout {
        width: 978px;
    }
}

@media (max-width: 992px) {
    .Layout {
        width: 100%;
    }
}
 */
