.Guest {
    font-size: var(--font-size-text);
    display: grid;
    column-gap: 15px;
    grid-template-columns: /*auto*/ 1.5fr 1fr 1fr 1fr 1fr;
    align-items: center;
    padding: 6px 14px;
    padding-top: 7px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid transparent;
    position: relative;

    &.Checked {
        border-color: var(--color-primary);
    }
    &.JustAdded {
        border-color: var(--color-success);
    }
    &.MoreInfoOpen {
        z-index: 11;
    }
    &.SelectMode {
        grid-template-columns: auto 1.5fr 1fr 1fr 1fr 1fr;
        &:hover {
            cursor: pointer;
        }
    }
}

.LoadingSpinner {
    justify-self: self-end;
}

.Icon {
    font-size: 1.8rem;
}

.AditionalInfo {
    position: relative;
}

.AditionalInfoContainer {
    padding: 5px;
}

.CheckIcon {
    font-size: 2rem;
    color: var(--color-darker-grey);
    margin-right: 5px;
}

.GuestContainer {
    position: relative;
}

.Label {
    font-weight: bold;
    margin-bottom: 2px;
    font-size: 1.4rem;
}

.Enter {
    max-height: 0;
}

.EnterActive {
    max-height: 400px;
    overflow: hidden;

    transition: max-height 250ms;
}

.Exit {
    max-height: 400px;
}

.ExitActive {
    max-height: 0;
    transition: max-height 250ms;
    overflow: hidden;
}

.ExitDone {
    max-height: 0;
    margin-top: -5px;
}

.IconRight {
    color: var(--color-dark-grey);
    font-size: 2.4rem;
    transition: transform 250ms;

    &.Opened {
        transform: rotate(90deg);
    }
}
