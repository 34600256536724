.SpouseItem {
    font-size: 1.6rem;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    background-color: white;
    border-radius: 5px;
    padding: 10px 18px;
    align-items: center;

    .Name {
        flex: 1 1 40%;
    }

    .Email {
        flex: 1 1 35%;
    }

    .Status {
        flex: 1 1 25%;
        justify-content: flex-end;
        display: flex;
    }

    .IconIMG {
        width: 20px;
        margin-right: 15px;
    }

    .IconFA {
        font-size: 2.2rem;
        color: var(--color-muted-black);
        margin-right: 15px;
    }
}
