.User {
    font-size: 1.6rem;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    background-color: white;
    border-radius: 5px;
    padding: 10px 18px;
    align-items: center;
}

.NameColumn {
    flex: 1 1 30%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.StatusColumn {
    flex: 1 1 25%;
    display: flex;
    justify-content: center;
}

.ActionButtons {
    display: flex;
    & > *:not(:last-child) {
        margin-right: 10px;
    }
}

.EmailColumn {
    display: flex;
    align-items: center;
    flex: 1 1 45%;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
