.ViewSelect {
    position: relative;
    color: var(--color-primary);
}

.SelectedOption {
    display: flex;
    font-size: 1.8rem;
    align-items: center;
    & > *:not(:last-child) {
        margin-right: 10px;
    }
    cursor: pointer;
    user-select: none;
}
