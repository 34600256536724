.Title {
    font-size: var(--font-size-heading);
    color: var(--color-primary);
}

.WeddingsContainer {
    margin-top: 15px;
}

.Wedding {
    font-size: 1.8rem;
    background-color: white;
    border-radius: 5px;

    align-items: center;
    margin-bottom: 15px;
    padding: 5px 15px;
    border: 1px solid transparent;
    box-shadow: var(--box-shadow-light);

    &:hover {
        border-color: var(--color-primary);
        cursor: pointer;

        & > .IconRight {
            color: var(--color-primary);
        }
    }
}

.MobileButton {
    position: fixed;
    right: 15px;
    bottom: 15px;
}

.Header {
    font-size: 1.6rem;
    font-weight: bold;
    padding: 5px 15px;

    & > .IconRight {
        opacity: 0;
        height: 20px;
        /* height: 0; */
    }
}

.Row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr auto;
}

.GuestCount,
.GuestCountHeader,
.Date {
    justify-self: center;
}

.GuestCount {
    color: var(--color-success);
}

.IconRight {
    color: var(--color-dark-grey);
    font-size: 3.6rem;
}

.HeaderTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ControlButtons {
    display: flex;
    justify-content: flex-end;

    > *:not(:last-child) {
        margin-right: 10px;
    }
}

.InputContainer {
    margin-top: 5px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    transition: transform 250ms;
}

.InputContainerOuter {
    overflow: hidden;
}

@media (max-width: 600px) {
    .HeaderTitle {
        justify-content: center;
    }
    .Row {
        grid-template-columns: 1fr auto;
    }
    .NameHeader,
    .GuestCountHeader,
    .GuestCount,
    .Date {
        display: none;
    }
    .Header {
        display: none;
    }
}

.Enter {
    transform: translateX(100%);
}

.EnterActive {
    transform: translateX(0);
}

.ExitActive {
    transform: translateX(-100%);
}
