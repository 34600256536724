$height: 24px;
$width: 24px;

.Checkbox {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}

.CheckboxLabel {
  transform: scale(0.7);
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  line-height: $height;
  height: $height;
  width: $width;
  clear: both;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .CheckboxCustom {
      background-color: var(--color-primary);
      border-radius: 5px;
      -webkit-transform: rotate(0deg) scale(1);
      -ms-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
      opacity: 1;
      border: 1px solid var(--color-primary);

      &::after {
        -webkit-transform: rotate(45deg) scale(1);
        -ms-transform: rotate(45deg) scale(1);
        transform: rotate(45deg) scale(1);
        opacity: 1;
        left: 8px;
        top: 3px;
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 2px 2px 0;
        background-color: transparent;
        border-radius: 0;
      }
    }
  }

  .CheckboxCustom {
    position: absolute;
    top: 0px;
    left: 0px;
    height: $height;
    width: $width;
    background-color: transparent;
    border-radius: 5px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    border: 1px solid var(--color-primary);

    &::after {
      position: absolute;
      content: "";
      left: 12px;
      top: 12px;
      height: 0px;
      width: 0px;
      border-radius: 5px;
      border: solid var(--color-primary);
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(0deg) scale(0);
      -ms-transform: rotate(0deg) scale(0);
      transform: rotate(0deg) scale(0);
      opacity: 1;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
    }
  }
}

.Label {
  margin-left: 5px;
  font-size: 1.2rem;
}
