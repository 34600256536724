.DeleteBtnContainaer {
    display: flex;
    justify-content: flex-end;
}

.TimeManagerContainer {
    display: inline-flex;
}

.Label {
    font-weight: bold;
    padding-left: 4px;
}
