.ListItem {
    display: flex;
    font-size: 1.6rem;
    padding: 6px 14px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
    align-items: center;
    border: 2px solid transparent;

    &:hover:not(.Disabled) {
        cursor: pointer;
        &:not(.LastColumn):not(.Selected) {
            border-color: var(--color-darker-grey);
        }
    }

    &.Selected {
        //background-color: var(--color-light-primary);
        border-color: var(--color-primary);
        .IconAngleRight {
            display: block;
            color: var(--color-primary);
        }
    }

    .IconIMG {
        width: 20px;
        margin-right: 15px;
    }

    .IconFA {
        font-size: 2.2rem;
        color: var(--color-muted-black);
        margin-right: 15px;
    }

    .Text {
        flex: 1;
        font-size: 2rem;
        margin-right: 15px;
    }

    .IconAngleRight {
        font-size: 3rem;
        color: var(--color-darker-grey);
        margin-left: 10px;
    }
}
