.LocationPicker {
    &.Inline {
        margin-bottom: 0px;
        .Input {
            border-radius: 0px;
            border: 0px;
            border-bottom: 1px solid #e9e9e9;
            padding: 4px 0px;
            &:focus {
                border-bottom-color: var(--color-primary);
                outline: none;
            }
        }
    }

    &.HasError .Input {
        border-color: var(--color-alert);
    }
}

.Input {
    display: block;
    width: 100%;

    border-radius: 100px;
    border: 1px solid #e9e9e9;
    font-size: var(--font-size-text);
    padding: 10px 20px;
    position: relative;
    z-index: 2;
    font-family: "Poppins";

    &:focus {
        border: 1px solid var(--color-primary);
        border-radius: 100px;
        outline: none;
    }

    &::placeholder {
        color: #cecece;
    }
}
