.Payments {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.Header {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.HeaderTitle {
    font-size: 2.2rem;
    color: var(--color-primary);
}

.ActionIcons {
    display: flex;
    margin-right: 10px;
    & > *:not(:last-child) {
        margin-right: 15px;
    }
}

.NoItemsMessage {
    padding: 10px;
    font-style: italic;
    text-align: center;
    color: var(--color-dark-grey);
    margin-top: 15px;
}

.Total {
    color: white;
    padding: 15px;
    font-size: 3.6rem;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    background-color: var(--color-success);
}
