.ButtonEnter,
.ButtonExitActive {
    transform: rotate3d(1, 0, 0, 90deg);
}

.ButtonEnterActive,
.ButtonExit {
    transform: rotate3d(1, 0, 0, 0deg);
}

.ButtonPrevEnter {
    opacity: 0;
}

.ButtonPrevEnterActive {
    opacity: 1;
}

.ButtonPrevExit {
    opacity: 1;
}

.ButtonPrevExitActive {
    opacity: 0;
}

.NotEnoughCredits {
    display: flex;
    flex-direction: column;
    align-items: center;
    .Icon {
        font-size: 7rem;
        color: var(--color-alert);
        margin-bottom: 25px;
    }
    .Explanation {
        margin-bottom: 25px;
    }
}

.NewBalance {
    margin-bottom: 10px;
}
