.Tooltip {
    position: absolute;
    font-size: 1.2rem;
    color: white;
    background-color: var(--color-primary);
    text-align: center;
    padding: 2px 5px;
    border-radius: 5px;
    //display: none;

    &:after,
    &:before {
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        top: 0;
    }

    &.Bottom {
        transform: translateX(-50%);
        left: 50%;
        top: 100%;

        &:after,
        &:before {
            left: 50%;
            transform: translate(-50%, -100%);
        }

        &:after {
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid var(--color-primary);
        }
    }

    &.Top {
        transform: translate(-50%, -100%);
        left: 50%;
        top: -5px;

        &:after,
        &:before {
            left: 50%;
            transform: translateX(-50%);
            top: calc(100% - 1px);
        }

        &:after {
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid var(--color-primary);
        }
    }
    &.Left {
        transform: translate(-100%, -50%);
        top: 50%;
        left: -7px;
        z-index: 2;

        &:after,
        &:before {
            left: 100%;
            top: 50%;
            transform: translate(-1px, -50%);
            z-index: 1;
        }

        &:after {
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid var(--color-primary);
        }
    }
    &.Right {
        transform: translateY(-50%);
        top: 50%;
        left: 100%;

        &:after,
        &:before {
            left: 0;
            top: 50%;
            transform: translate(-100%, -50%);
        }

        &:after {
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-right: 5px solid var(--color-primary);
        }
    }
}
