.Title {
    font-weight: bold;
    overflow: hidden;
    overflow: hidden;
    /*  white-space: nowrap; */
    text-overflow: ellipsis;
}

.TitleValue {
    margin-bottom: 5px;
}

.TitleNoValue {
    padding: 2.5px 0px;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
}

.BarsIcon {
    margin-right: 10px;
    cursor: grab;
}

.TitleContainer {
    /*  display: flex; */
    align-items: center;
    justify-content: space-between;
    /* overflow: hidden; */
    /* flex: 0 1 40%; */
}

.EditButtonContainer {
    margin-left: 10px;
    display: flex;
    & > *:not(:last-child) {
        margin-right: 5px;
    }
    justify-self: flex-end;
}

.Type {
    color: var(--color-darker-grey);
    font-style: italic;
    margin-left: 10px;
    flex: 1;
}

.EditModeView {
    display: flex;
    align-items: center;
}

.TitleType {
    display: flex;
    align-items: center;
}
