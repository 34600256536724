.Input {
    font-family: "Poppins";
    font-size: 1.6rem;
    //border-radius: 10px;
    display: block;
    width: 100%;
    z-index: 2;
    border-radius: 100px;
    border: 1px solid #e9e9e9;
    font-size: var(--font-size-text);
    padding: 10px 20px;
    position: relative;

    &:focus {
        border: 1px solid var(--color-primary);
        border-radius: 100px;
        outline: none;
    }

    &::placeholder {
        color: var(--color-dark-grey);
    }
}

.TimePicker {
    position: relative;
    &.HasError {
        .Input {
            border-color: var(--color-alert);
            &:focus {
                border-color: var(--color-alert);
            }
        }
    }
    &.Inline {
        .Input {
            border: 0px;
            padding: 0px;
            border: 0;
            border-bottom: 1px solid #e9e9e9;
            border-radius: 0px;
            &:focus {
                border-color: var(--color-primary);
                outline: none;
            }
        }
    }
}

.PickerContainer {
    position: absolute;
    top: 5px;
    left: 5px;
    top: calc(100% + 5px);
    z-index: 1200;
}
