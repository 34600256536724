*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
}

body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    //line-height: 1.7;
    background-color: #f8fff4;
    box-sizing: border-box;

    touch-action: pan-x pan-y;
}

html,
body {
    height: 100vh;
}

:root {
    --color-success: #28a745;
    --color-warning: #ffc107;
    --color-alert: #dc3545;
    --color-light-alert: #ffe7e5;

    //--color-light-success: #a9dbb4;
    --color-light-success: #d4edd9;

    --color-light-warning: #ffecb4;

    --color-dark-alert: #ae1e2c;
    --color-dark-success: #1d7d33;
    --color-dark-warning: #c49300;

    --color-lighter-grey: #fafafa;
    --color-light-grey: #f6f6f6;
    --color-grey: #f0f0f0;
    --color-dark-grey: #d2d2d2;
    --color-darker-grey: #b1b1b1;
    --color-muted-black: #5f6368;

    --color-icon: #050505;

    --color-background: #f4f8f9;

    --font-size-heading: 3.2rem;
    --font-size-title: 2.2rem;
    --font-size-text: 1.6rem;

    --width-phone: 600px;

    --margin-top-container: 10px;
    --margin-bottom-section: 15px;
    --margin-bottom-item: 10px;
    --margin-bottom-header: 5px;

    --box-shadow: 0 1px 3px rgba(60, 64, 67, 0.3),
        0 4px 8px 3px rgba(60, 64, 67, 0.15);
    --box-shadow-light: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
}
