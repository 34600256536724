.NewOption {
    display: flex;
    align-items: center;
    padding: 2.5px 10px;
    position: relative;
    background-color: white;
    z-index: 1;

    & > *:not(:last-child) {
        margin-right: 10px;
    }
}

.InputNewOption {
    border: 1px solid #e9e9e9;
    font-size: var(--font-size-text);
    position: relative;
    z-index: 2;
    font-family: "Poppins", sans-serif;

    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    width: 100%;
    min-width: 16rem;

    &:focus {
        border: 1px solid var(--color-primary);
        border-radius: 5px;
        outline: none;
    }

    &::placeholder {
        color: #cecece;
    }
}

.IconPlus {
    &:hover {
        cursor: pointer;
        color: var(--color-primary);
    }
}
