.SideBarItems > *:not(:last-child) {
    margin-bottom: 5px;
}

.SideBarTitle {
    display: none;
}

.Logo {
    width: var(--sidebar-logo-width);
    padding-top: var(--sidebar-logo-padding-top);
    padding-bottom: var(--sidebar-logo-padding-bottom);
}

@media (max-width: 600px) {
    .SideBar {
        position: fixed;
        top: 0px;
        bottom: 0;
        left: 0;
        width: 75%;
        background-color: white;
        z-index: 1050;
        //display: none;
        transition: transform 200ms;
        transform: translateX(-100%);

        &.Open {
            transform: translateX(0);
        }
    }

    .SideBarTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        // color: var(--color-primary);
        background-color: var(--color-primary);
        font-size: 2.4rem;
        font-family: "Pacifico", cursive;
        padding: 5px 10px;
        margin-bottom: 15px;
    }
}
