.Day {
  padding: 5px;
  margin: 1px;
  font-size: 1.2rem;
  border-radius: 5px;
  align-self: center;
  text-align: center;
  border: 2px solid transparent;
  &:hover:not(.Selected) {
    background-color: var(--color-light-grey);
    cursor: pointer;
  }
  user-select: none;
}

.OtherMonthDay {
  color: var(--color-dark-grey);
}

.Today {
  border: 1px solid var(--color-primary);
}

.Selected {
  background-color: var(--color-primary);
  color: white;
}
