.SeatingManager {
    position: relative;
    overflow: hidden;
}

.AddButton {
    font-size: 2.2rem;

    //margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    color: white;
    background: var(--color-success);
    //border-radius: 25px;
    cursor: pointer;
    user-select: none;
    &.Disabled {
        background-color: var(--color-darker-grey);
        cursor: not-allowed;
    }
}

.NoGuests {
    font-size: 1.6rem;
    color: var(--color-darker-grey);
    padding-top: 15px;
    margin: 0px 20px;

    text-align: center;
}

.GuestList {
    height: 100%;
    overflow: auto;
    font-size: 1.6rem;
    border-left: 1px solid var(--color-light-grey);
    &::-webkit-scrollbar {
        width: 0px;
    }
}

.Overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    background: rgba(0, 0, 0, 0.4);
}

.HeaderContainer {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-light-grey);
    border-left: 1px solid var(--color-light-grey);
    padding: 16px;

    .ToggleContainer {
        flex: 1;
    }

    .Times {
        font-size: 3rem;
        color: var(--color-alert);
        margin-left: 15px;
    }
}

.SinglePaneButton {
    position: fixed;
    bottom: 10px;
    left: 5px;
    right: 5px;
    z-index: 55;
    display: flex;
    justify-content: center;
    transition: transform 200ms;
    transform: translateY(calc(100% + 10px));
    &.Showing {
        transform: translateY(0);
    }
}

@media (max-width: 992px) {
    .SeatingManager {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 35;
        transform: translateY(100%);
        transition: transform 200ms;
    }
}
