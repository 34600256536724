.Container {
    padding: 15px;
    font-size: 1.6rem;
}

.SectionHeader {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 35px;
    padding: 10px;
    justify-content: center;
    margin-top: 10px;
}

.Progression {
    height: 2px;
    background-color: var(--color-light-grey);
    border-radius: 5px;
    width: 6rem;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        height: 2px;
        width: 0;
        top: 0;
        left: 0;
        background-color: var(--color-primary);
        transition: width 200ms ease-out;
    }
}

.Progression.Filled::after {
    width: 100%;
}
