.SelectedCategory {
    padding: 3px 12px;
    border-radius: 5px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    background-color: rgba(206, 205, 202, 0.1);
}

.NoCategories {
    display: flex;
    flex-flow: column;
    align-items: center;
    color: var(--color-muted-black);
    text-align: center;
}

.NoCategoriesText {
    font-size: 1.2rem;
}

.CategoryManager {
    width: 16rem;
    /* max-width: 14rem; */
    justify-self: center;
    font-size: 1.6rem;
}

.Value {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
