.ContextSelectOption {
    display: flex;
    align-items: center;
    & > :not(:last-child) {
        margin-right: 10px;
    }
    padding: 2.5px 10px;
    justify-content: space-between;
    font-size: var(--font-size-text);

    &:hover {
        cursor: pointer;
        background-color: var(--color-light-grey);

        & .Trash {
            visibility: visible;
        }
    }
}

.Value {
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
}

.Image {
    width: 20px;
}

.Color {
    width: 18px;
    height: 18px;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset;
    border-radius: 3px;
}

.Trash {
    visibility: hidden;
    color: var(--color-dark-grey);

    &:hover {
        color: var(--color-alert);
    }
}

@media (max-width: 600px) {
    .Trash {
        visibility: visible;
    }
}
