.ShowOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    &:hover {
        background-color: var(--color-grey);
        cursor: pointer;
    }
}

.Option {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.CheckIcon {
    color: var(--color-success);
    margin-right: 5px;
}
