.ImageContent {
    object-fit: cover;
    width: 100%;

    &.FullScreen {
        width: unset;
        max-width: 100%;
        max-height: 100%;
        padding: 12px;
    }
}
