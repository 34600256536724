.ColorsContent {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(65px, 1fr));
    //grid-auto-flow: column;
    width: 100%;
    position: relative;

    &.FullScreen {
        position: absolute;
        top: 0;
        bottom: 0;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        overflow: auto;
    }
}

.Color {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.PantoneName {
    padding: 12px;
    font-size: 3.2rem;
    color: white;
    margin-bottom: 12px;
}

@media (max-width: 516px) {
    .Color {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .PantoneName {
        margin-bottom: 0px;
    }

    .ColorsContent {
        &.FullScreen {
            grid-template-columns: repeat(auto-fit, minmax(65px, 1fr));
        }
    }
}
