.DigitPicker {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Input {
    font-size: 4.2rem;
    text-align: center;
    border: 0px;
    width: 70px;
    border-bottom: 1px solid #e9e9e9;
    margin: 15px 0px;

    &:focus {
        outline: none;
        border-color: var(--color-primary);
    }
}

.ChevronIcon {
    cursor: pointer;
    font-size: 2rem;
    &:hover {
        color: var(--color-primary);
    }
}
