.SupplierInfo {
    padding: 15px;
}

.Label {
    font-weight: bold;
    margin-bottom: 6px;
    margin-left: 12px;
}

.ButtonsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 2.2rem;
}

.IconArrow {
    color: var(--color-primary);
}

.ButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.Separator {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .Line {
        height: 1px;
        flex-grow: 1;
        background-color: var(--color-primary);
    }
    .SeparatorText {
        color: var(--color-primary);
        margin: 0px 12px;
    }
}

.IconArrow {
    cursor: pointer;
}
