.TableSelectOptions {
    display: flex;
    align-items: center;
    & > *:not(:last-child) {
        margin-right: 20px;
    }
    font-size: 1.6rem;
    box-shadow: var(--box-shadow);
    background: white;
    padding: 6px 12px;
    border-radius: 5px;
}

.MultiSelectActionContainer {
    position: relative;
}

.TableCounter {
    white-space: nowrap;
}
