.SupplierList {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.List {
    flex-grow: 1;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-track {
        background: hsla(0, 0%, 100%, 0.1);
    }
    scrollbar-color: rgba(0, 0, 0, 0.2) rgba(255, 255, 255, 0.1);
    scrollbar-width: thin;
}
.SearchBar {
    background-color: var(--color-grey);
    padding: 15px 5px;
    padding-right: 15px;
    display: flex;
    align-items: center;
}

.Back {
    font-size: 4rem;
    color: var(--color-primary);
    margin-left: 5px;
    margin-right: 15px;
    cursor: pointer;
}

.AddSupplierButton {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    background-color: var(--color-success);
    color: white;
    &:hover {
        cursor: pointer;
        /*  background-color: white;
        color: var(--color-success); */
    }
}

.AddSupplierText {
    font-size: 2rem;
}

.PlusIcon {
    /* color: var(--color-success); */
    margin-right: 10px;
    font-size: 3rem;
}

.NoSuppliersMessage {
    color: var(--color-dark-grey);
    margin-top: 15px;
    text-align: center;
    font-style: italic;
    padding: 0px 15px;
}
