.TableContainer {
    position: absolute;
    z-index: 15;
}

.Tables {
    position: relative;
    //margin-top: var(--margin-top-container);
}

.PageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Buttons {
    display: flex;
    align-items: center;
}

.StatsContainer {
    font-size: 1.6rem;
    display: flex;

    & > *:not(:last-child) {
        margin-right: 25px;
    }
}

.StatsNumber {
    padding: 0px 5px;
    background-color: var(--color-success);
    color: white;
    border-radius: 5px;
}
