.OptionsContainer {
    display: flex;
    align-items: center;
}

.Options {
    display: flex;
    flex-flow: column;
    & > *:not(:last-child) {
        margin-bottom: 5px;
    }
}

.OptionsContainer {
    display: flex;
    flex-flow: column;
    & > .Options:not(:last-child) {
        margin-bottom: 30px;
    }
}

.IconContainer {
    position: relative;
}
