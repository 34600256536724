.ProgressBar {
    height: 5px;
    background-color: var(--color-grey);
    width: 100%;
    margin-top: 10px;
    border-radius: 10px;
}

.Progress {
    height: 100%;
    background-color: var(--color-primary);
    width: 0%;
    border-radius: 10px;
    transition: width 200ms;
}

.Stats {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    font-size: 1.2rem;
    margin-left: 5px;
    margin-right: 5px;
    color: var(--color-darker-grey);
}
