.Filters {
    display: flex;
    align-items: center;
    & > *:not(:last-child) {
        margin-right: 15px;
    }
    margin-left: 15px;
    margin-top: 2px;
}

.Filter {
    font-size: 1.6rem;
    color: var(--color-primary);
    padding: 2px 12px;
    border-radius: 20px;
    text-decoration: underline;
    cursor: pointer;
}
