.Counters {
    display: flex;
    margin-bottom: 12px;
    justify-content: center;
}

.Counter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.Attending {
        color: var(--color-success);
    }
    &.Pending {
        color: var(--color-darker-grey);
    }

    .Number {
        font-size: 8.2rem;
        line-height: 7rem;
        margin-bottom: 4px;
        &.Attending {
            color: var(--color-success);
        }
    }

    /*  &:not(:last-child) {
        margin-right: 45px;
    } */
}

.Line {
    width: 1px;
    background-color: var(--color-dark-grey);
    margin: 8px 45px;
    //height: 100%;
}

.Total {
    display: flex;
    justify-content: center;
    color: var(--color-primary);
}

@media (max-width: 600px) {
    .Counter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &.Attending {
            color: var(--color-success);
        }
        &.Pending {
            color: var(--color-darker-grey);
        }

        .Number {
            font-size: 6.6rem;
            line-height: 5.6rem;
            margin-bottom: 4px;
            &.Attending {
                color: var(--color-success);
            }
        }

        /*  &:not(:last-child) {
            margin-right: 45px;
        } */
    }
}
