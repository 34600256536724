.IconOption {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    color: var(--color-muted-black);
    cursor: pointer;

    &.Selected {
        color: var(--color-primary);
    }
}

.Icon {
    font-size: 2.2rem;
    margin-bottom: 2px;
}

.Value {
    text-align: center;
}
