.Anchor {
  &:link,
  &:active,
  &:visited {
    color: var(--color-primary);
  }

  text-align: center;
  display: block;
  font-size: 1.2rem;
}
