.Message {
    display: flex;
    align-items: center;
    font-size: var(--font-size-text);
    padding: 7px 15px;
    border-radius: 10px;
    overflow-x: hidden;
    /* animation: entrance 0.4s ease-out; */

    &.Info {
        background-color: var(--color-light-grey);
        color: var(--color-muted-black);
        border: 1px solid var(--color-dark-grey);
    }

    &.Success {
        background-color: var(--color-success);
        color: white;
    }

    &.Alert {
        background-color: var(--color-dark-alert);
        color: white;
    }

    &.Primary {
        background-color: var(--color-primary);
        color: white;
    }
}

@keyframes entrance {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.Icon {
    margin-right: 15px;
    font-size: 2.2rem;
}
