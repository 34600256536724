.DeleteConfirmationText {
    text-align: center;
}

.DeleteConfirmationButtons {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    & > div {
        &:hover {
            cursor: pointer;
        }
        &:not(:last-child) {
            margin-right: 15px;
        }
    }
}

.GuestDeleteButton {
    position: relative;
}
