.TitleEditor {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.InputContainer {
    & > *:not(:last-child) {
        margin-right: 15px;
    }
    display: flex;
    align-items: center;
}

.ActionButtonContainer {
    display: flex;
    align-items: center;
    margin-left: 10px;
    & > *:not(:last-child) {
        margin-right: 5px;
    }
}
