.TablesContainer {
    display: flex;
    align-items: center;
    & > *:not(:last-child) {
        margin-right: 5px;
    }
}

.TableManager {
    font-size: var(--font-size-text);
}
