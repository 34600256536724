.Select {
    position: relative;
}

.SelectedOption {
    display: flex;
    border-radius: 100px;
    border: 1px solid #e9e9e9;
    align-items: center;
    background-color: white;
    padding: 0px 20px;
    justify-content: space-between;
    &:hover {
        cursor: pointer;
    }

    &.Inline {
        border-radius: 0px;
        border-bottom: 1px solid #e9e9e9;
        /* padding: 0px 8px; */
        padding: 4px 8px;
        border-radius: 5px;
        input {
            padding: 0px;
        }
    }

    &.Open {
        border-color: var(--color-primary);
        .Icon {
            color: var(--color-primary);
        }
    }

    &.HasError {
        border-color: var(--color-alert);
    }
}

.InputControl {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    font-size: var(--font-size-text);
    padding: 10px 0px;
    border: 0px;
    user-select: none;
    pointer-events: none;

    &:focus {
        outline: none;
        background-color: transparent;
    }

    &:hover {
        cursor: pointer;
    }

    &::placeholder {
        color: #cecece;
    }
}

.OptionsWrapper {
    position: absolute;
    width: 90%;
    transition: all 0.2s;
    margin-left: 20px;
    max-height: 0;
    overflow-y: hidden;
}

.Options {
    background-color: white;
    border: 1px solid var(--color-primary);
    border-top: 0px;
}

.Enter {
    max-height: 0;
}

.EnterActive,
.EnterDone {
    max-height: 200px;
}

.ExitActive,
.ExitDone {
    max-height: 0;
}

.Icon {
    font-size: 2.4rem;
}
