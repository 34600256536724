.SectionIcon {
    position: relative;

    &.WithError {
        .IconWrapper {
            border-color: var(--color-alert);
        }
        .Text {
            color: var(--color-alert);
        }
    }
}

.IconWrapper {
    padding: 10px;
    border-radius: 100px;
    background-color: var(--color-light-grey);
    height: 6.5rem;
    width: 6.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-muted-black);
    transition: color 200ms ease-out, background-color 200ms ease-out;
    border: 2px solid transparent;

    &.Active {
        color: white;
        background-color: var(--color-primary);
    }
}

.Icon {
    font-size: 3.4rem;
    width: 3.4rem;
}

.Text {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    margin-top: 7px;
    font-size: 1.4rem;
    white-space: nowrap;
}
