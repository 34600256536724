.Body {
    font-size: 1.6rem;
    padding: 15px;
}

.ColumnContainer {
    display: flex;

    & > *:first-child {
        margin-right: 10px;
    }
    &:not(:first-child) {
        cursor: pointer;
    }
    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

.ColumnsContainer {
    margin-left: 10px;
    margin-bottom: 20px;
    display: inline-flex;
    flex-direction: column;
}

.Instructions {
    margin-bottom: 20px;
}

.ButtonContainer {
    display: flex;
    justify-content: center;
}

.LoadingContainer {
    margin-top: 25px;
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .LoadingText {
        margin-top: 10px;
    }
}
