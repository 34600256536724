.Error {
    // font-family: "Poppins", sans-serif;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Title {
    font-size: 4.4em;
    // color: #ff415a;
}

.Image {
    margin-bottom: 10px;
}

.Description {
    font-size: 1.6rem;
    margin-bottom: 30px;
    //color: var(--color-primary);
}
