.BudgetBox {
    flex-grow: 1;
    background-color: white;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.List {
    flex-grow: 1;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-track {
        background: hsla(0, 0%, 100%, 0.1);
    }
    scrollbar-color: rgba(0, 0, 0, 0.2) rgba(255, 255, 255, 0.1);
    scrollbar-width: thin;
}

.Total {
    color: white;
    padding: 15px;
    font-size: 3.6rem;
    display: flex;
    justify-content: center;
    border-radius: 0px 0px 10px 10px;
}

.SearchBar {
    background-color: var(--color-grey);
    padding: 15px 5px;
    border-radius: 10px 10px 0px 0px;
}

.OptionsBar {
    position: relative;
    display: flex;
    background-color: var(--color-light-grey);
    align-items: center;
    justify-content: space-between;
}

.Mobile {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 35;
    transform: translateY(calc(100% + 15px));
    transition: transform 300ms;
    display: flex;
    flex-direction: column;

    &.Show {
        transform: translateY(0);
    }

    .Total,
    .SearchBar {
        border-radius: 0;
    }
}

.MobileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 2.6rem;
    padding: 12px;
}

.AddButton {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 40;
}

@media (max-height: 700px) {
    .Total {
        font-size: 2.4rem;
        padding: 10px;
    }
    .SearchBar {
        padding: 5px;
    }
}
