.CounterCategory {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
}
.Counters {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    &:not(:last-child) {
        margin-bottom: 20px;
    }

    &.Occupied {
        color: var(--color-success);
    }

    &.Total {
        color: var(--color-primary);
        color: var(--color-darker-grey);
    }
}

.Number {
    font-size: 8.2rem;
    line-height: 7rem;
    margin-bottom: 0px;
}

.CounterTitle {
    margin-bottom: 12px;
}

.Subtitle {
    display: flex;
    justify-content: center;
    color: var(--color-primary);
}

.Line {
    width: 1px;
    background-color: var(--color-dark-grey);
    margin: 45px 45px;
    //height: 100%;
}

.CounterContainer {
    display: flex;
    justify-content: center;
}

@media (max-width: 600px) {
    .Number {
        font-size: 5.2rem;
        line-height: 4.2rem;
    }
}
