.InvitedByFilter {
    font-size: var(--font-size-text);
    position: relative;
}

.Label {
    position: absolute;
    top: 5px;
    margin-left: 17px;
    z-index: 5;
    font-weight: bold;
}

.EnterActive {
    top: -14px;
    font-size: 12px;
    transition: all 250ms;
}

.EnterDone {
    top: -14px;
    font-size: 12px;
    opacity: 1;
}

.Exit {
    top: -14px;
    font-size: 12px;
    opacity: 1;
}

.ExitActive {
    top: 5px;
    font-size: 1.6rem;
    transition: all 250ms;
}
