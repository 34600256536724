.SectionSeparator {
    margin-bottom: var(--margin-bottom-section);
}

.ItemSeparator {
    margin-bottom: var(--margin-bottom-item);
}

.HeaderSeparator {
    margin-bottom: var(--margin-bottom-header);
}
