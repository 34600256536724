.Options {
    display: flex;
    align-items: center;

    & > div:not(:last-child) {
        margin-right: 15px;
    }
}

.MobileButtons {
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    z-index: 25;
    padding: 15px;
    & > *:not(:last-child) {
        margin-right: 5px;
    }
}
