.Card {
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 22px;
    margin-bottom: 25px;
    cursor: pointer;
}

.Title {
    font-weight: bold;
    color: var(--color-primary);
    font-size: 2.2rem;
    margin-bottom: 12px;
}
