.Guest {
    font-size: var(--font-size-text);
    display: grid;
    column-gap: 15px;
    align-items: center;
    padding-top: 7px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid transparent;
    position: relative;
    grid-template-columns: 1fr min-content;
    border-left: 4px solid;
    padding: 6px 8px;

    &.SelectMode {
        grid-template-columns: min-content 1fr min-content;
    }

    &.Checked {
        border-color: var(--color-primary);
    }
}

.ActionButtons {
    display: flex;
    & > *:not(:last-child) {
        margin-right: 5px;
    }
}
