.Label {
    font-weight: bold;
    margin-bottom: 4px;
    margin-left: 12px;
}

.InputContainer {
    margin-bottom: 16px;
}

.LinkPreview {
    display: flex;
    background-color: var(--color-light-grey);
    margin: 0px 15px;
    border-radius: 10px 10px 0px 0px;

    .Details {
        padding: 6px 12px;

        .Title {
            font-weight: bold;
        }

        .Description {
            font-size: 1.4rem;
        }
    }

    .Image {
        height: 125px;
        width: 125px;
        object-fit: cover;
    }
}

.ImagePreview {
    background-color: var(--color-light-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 12px;

    img {
        max-height: 350px;
        max-width: 350px;
        margin-bottom: 10px;
    }
    .Details {
        display: flex;
        align-items: center;

        .Name {
            margin-right: 10px;
        }

        .Icon {
            color: var(--color-alert);
            cursor: pointer;
            font-size: 1.8rem;
            cursor: pointer;
        }
    }
}
