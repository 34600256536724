.Number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 8;
}

.RoundTable {
    position: relative;
    z-index: 5;
}

.TableContainer {
    position: relative;
    cursor: pointer;

    &:not(.Selected):hover {
        & > div {
            background-color: var(--color-light-grey);
        }
    }
}

.Table {
    border: 2px solid black;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 8;
}
