.BudgetCounter {
    &.Expenses {
        color: var(--color-alert);
    }
    &.Payments {
        color: var(--color-success);
    }
    &.Remaining {
        color: var(--color-primary);
    }
    &:not(:last-child) {
        margin-bottom: 16px;
    }
}

.BudgetNumber {
    font-size: 4.2rem;
    line-height: 3.6rem;
}

.BudgetLabel {
    margin-bottom: 4px;
    color: black;
}
