.Subtitle {
    font-size: 2rem;
    color: var(--color-primary);
    font-weight: bold;
}

.NoItems {
    font-size: 1.6rem;
    color: var(--color-darker-grey);
    font-style: italic;
}

.List {
    position: relative;
}
