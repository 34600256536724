.SectionHeader {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 35px;
    padding: 10px;
    justify-content: center;
}

.Progression {
    height: 2px;
    background-color: var(--color-light-grey);
    border-radius: 5px;
    width: 6rem;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        height: 2px;
        width: 0;
        top: 0;
        left: 0;
        background-color: var(--color-primary);
        transition: width 200ms ease-out;
    }
}

.Progression.Filled::after {
    width: 100%;
}

.FormStepsWrapper {
    margin-bottom: 15px;
}

.Enter {
    opacity: 0;
}
.EnterActive {
    opacity: 1;
}
.Exit {
    opacity: 1;
}
.ExitActive {
    opacity: 0;
}
.EnterActive,
.ExitActive {
    transition: opacity 200ms;
}
