.Plan {
    font-size: 1.6rem;
    min-height: 100vh;
    padding-top: var(--margin-top-container);
    position: relative;
    padding-bottom: 60px;
    /* background: white;
    padding: 10px;
    border-radius: 5px; */
}

.Mobile {
    .PlanContent {
        grid-template-columns: 1fr;
    }
    .ActionButtons {
        position: fixed;
        bottom: 15px;
        right: 15px;
        display: flex;
        flex-direction: column;
        z-index: 15;
        & > *:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    .Header {
        justify-content: center;
    }
}
