.Explanation {
    margin-bottom: 20px;
}

.CreditPackagesContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.CreditPackage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    border: 1px solid var(--color-dark-grey);
    border-radius: 5px;
}

.CreditsAmount {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 15px;

    .Amount {
        font-weight: bold;
        font-size: 7rem;
        line-height: 6rem;
        margin-bottom: 5px;
        color: var(--color-primary);
    }
}

.Price {
    font-size: 2.4rem;
}

.Discount {
    font-size: 1.6rem;
    color: var(--color-primary);
}

.ContactRow {
    display: flex;
    align-items: center;
    .ContactIcon {
        margin-right: 10px;
    }
}

.ContactContainer {
    margin-left: 5px;
}

.PriceCurrency {
    margin-bottom: 20px;
    margin-left: 10px;
    font-style: italic;
    color: var(--color-darker-grey);
    font-size: 1.4rem;
}

.ContactMessage {
    margin-bottom: 5px;
}
