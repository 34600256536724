.Plan {
    font-size: 1.6rem;
    position: relative;
    padding-bottom: 60px;
}

.ActionButtons {
    display: flex;
    & > *:not(:last-child) {
        margin-right: 10px;
    }
}

.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.Title {
    font-weight: bold;
    margin-bottom: 5px;
}

.PlanContent {
    display: grid;
    grid-template-columns: 5fr 1fr;
    column-gap: 25px;
}

.AddButton {
    display: inline-flex;
    align-items: center;
    color: var(--color-primary);
    padding: 4px 8px;
    background-color: var(--color-lighter-grey);
    border-radius: 5px;
    /* &:not(:first-child) {
        margin-top: 10px;
    } */
    cursor: pointer;
    &:hover {
        color: white;
        background-color: var(--color-primary);
    }

    .AddIcon {
        margin-right: 5px;
    }
}

.Mobile {
    .PlanContent {
        grid-template-columns: 1fr;
    }
    .ActionButtons {
        position: fixed;
        bottom: 15px;
        right: 15px;
        display: flex;
        flex-direction: column;
        z-index: 15;
        & > *:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    .Header {
        justify-content: center;
    }
}
