.Counter {
    display: flex;
    justify-content: space-between;
}

.Level1 {
    font-size: 2.8rem;
    & > .NextSubdivision {
        margin-bottom: 15px;
    }
    .SpouseImage {
        width: 35px;
        margin-right: 10px;
    }
}

.Level2 {
    font-size: 2.4rem;
    margin-left: 15px;
    & > .NextSubdivision {
        margin-bottom: 10px;
    }
    .SpouseImage {
        width: 25px;
        margin-right: 10px;
    }
}

.Level3 {
    font-size: 2rem;
    margin-left: 30px;
    & > .NextSubdivision {
        margin-bottom: 5px;
    }
    .SpouseImage {
        width: 20px;
        margin-right: 10px;
    }
}

.Level4 {
    font-size: 1.6rem;
    margin-left: 45px;
}

.LineSeparator {
    height: 2px;
    background-color: var(--color-grey);
}

.NameContainer {
    display: flex;
    align-items: center;
}

.CategoryColor {
    height: 17px;
    width: 17px;
    border-radius: 5px;
    margin-right: 5px;
}

.StatusesIcon {
    margin-right: 10px;
}

.SpouseImage {
    width: 35px;
    margin-right: 10px;
}

.Angle {
    transform: rotate(90deg);
    margin-right: 10px;
    transition: transform 200ms;
}

.Number {
    cursor: pointer;
    &:hover {
        color: var(--color-primary);
    }
}
