.PopoverContainer {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 3px rgba(60, 64, 67, 0.3),
        0 4px 8px 3px rgba(60, 64, 67, 0.15);
    z-index: 10;

    &.WithTriangle {
        &:after,
        &:before {
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-top-color: var(--color-primary);
            border-width: 8px;
            margin-left: -8px;
        }

        &.Alert {
            &:after {
                border-top-color: var(--color-alert);
            }
        }
    }
}

.Popover {
    padding: 5px;
    /* background-color: white; */
    position: relative;
    z-index: 15;
}

.Rounded {
    border-radius: 8px;
}

.Top {
    top: -5px;
    transform: translateY(-100%);
    margin-bottom: 5px;
}

.TopCentered {
    top: -10px;
    left: 50%;
    transform: translate(-50%, -100%);
    &.WithTriangle {
        &:after,
        &:before {
            top: 100%;
            left: 50%;
        }
    }
}

.TopLeft {
    top: -10px;
    left: 100%;
    transform: translate(-100%, -100%);
    &.WithTriangle {
        &:after,
        &:before {
            top: 100%;
            left: 100%;
            transform: translateX(-100%);
        }
    }
}

.BottomRight {
    margin-top: 5px;
    right: 0;
}

.BottomLeft {
    margin-top: 5px;
    left: 0;
}

.Left {
    left: -10px;
    top: 0px;
    transform: translateX(-100%);
}

.Right {
    left: calc(100% + 5px);
    transform: translateY(-50%);
    margin-right: 5px;
}

.TopRight {
    left: calc(100% + 15px);
    top: -15px;
}

.Big {
    width: 32rem;
}

.Medium {
    width: 25rem;
}

.Small {
    width: 18rem;
}

.WholeWidth {
    width: 100%;
}

@media (max-width: 600px) {
    .Popover {
        z-index: 1010;
    }
    .PopoverContainer {
        &.Centered {
            position: fixed;
            top: 50%;
            left: 20px;
            right: 20px;
            transform: translateY(-50%);
            margin: 0;
            z-index: 1110;
            &.Enter {
                opacity: 0;
            }
            &.EnterActive {
                opacity: 1;
                transition: opacity 200ms;
            }
            &.Exit {
                opacity: 1;
            }
            &.ExitActive {
                opacity: 0;
                transition: opacity 200ms;
            }
            &.ExitDone {
                opacity: 0;
            }
        }
        &.FromBottom {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: unset;
            transform: unset;
            width: initial;
            z-index: 1110;
            padding-bottom: 15px;
            &.Enter {
                transform: translateY(100%);
            }
            &.EnterActive {
                transform: translateY(0px);
                transition: transform 200ms;
            }
            &.ExitActive {
                transform: translateY(100%);
                transition: transform 200ms;
            }
            &.ExitDone {
                transform: translateY(0px);
            }
        }
        &.FromTop {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: unset;
            transform: unset;
            width: initial;
            z-index: 1010;

            &.Enter {
                transform: translateY(-100%);
            }
            &.EnterActive {
                transform: translateY(0px);
                transition: transform 200ms;
            }
            &.ExitActive {
                transform: translateY(-100%);
                transition: transform 200ms;
            }
            &.ExitDone {
                transform: translateY(0px);
            }

            //padding-bottom: 15px;
        }
    }
}
