.Modal {
    width: 60rem;
    max-width: 100%;
    border-radius: 10px;

    position: fixed;

    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: 1010;
    background-color: white;

    max-height: 1156px;
    display: flex;
    flex-direction: column;

    .Content {
        flex: 1;
        overflow: auto;
    }

    &.Alert {
        .Header {
            background-color: var(--color-alert);
        }
    }

    &.Warning {
        .Header {
            background-color: var(--color-warning);
        }
    }

    &.Success {
        .Header {
            background-color: var(--color-success);
        }
    }
}

@media (max-height: 1256px) {
    .Modal {
        max-height: 996px;
    }
}

@media (max-height: 1056px) {
    .Modal {
        max-height: 725px;
    }
}

@media (max-height: 880px) {
    .Modal {
        max-height: 640px;
    }
}

@media (max-height: 696px) {
    .Modal {
        max-height: 580px;
    }
}

.Wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.Content {
    padding: 1.5rem;
    font-size: 1.6rem;
    position: relative;
    z-index: 1010;
    border-radius: 10px;
    background-color: white;
}

.Header {
    background-color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    color: white;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.HeaderContainer {
    display: flex;
    align-items: center;
}

.TitleContainer {
    display: flex;
    align-items: center;
}

.Title {
    font-size: 2.2rem;
    display: inline-block;
    margin-left: 15px;
}

.Times {
    font-size: 2.2rem;
    cursor: pointer;
}

.Footer {
    padding: 1.5rem;
}

.Buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1.5rem;
    position: relative;
    z-index: 15;
    background-color: var(--color-light-grey);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    & > *:not(:first-child) {
        margin-left: 10px;
    }
}

@keyframes ModalIn {
    0% {
        top: 0%;
        transform: translate(-50%, -100%);
    }

    100% {
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

@keyframes ModalOut {
    0% {
        top: 50%;
        transform: translate(-50%, -50%);
    }

    10% {
        top: 50%;
        transform: translate(-50%, 0);
    }

    100% {
        top: 0%;
        transform: translate(-50%, -100%);
    }
}

@keyframes ModalInStick {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes ModalOutStick {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
    }
}

@media only screen and (max-width: 600px) {
    .Modal {
        width: 90%;
    }

    .StickOnMobile {
        .Modal {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform: translateY(-100%);
            width: initial;
            display: flex;
            flex-direction: column;
            max-height: initial;
            .Header {
                border-radius: 0px;
            }
            .Buttons {
                border-radius: 0px;
            }
            .Content {
                flex: 1;
                overflow: auto;
            }
        }

        .EnterActive,
        .EnterDone {
            transform: translateY(0);
            transition: transform;
        }

        .Exit,
        .ExitActive {
            transition: transform;
            transform: translateY(-100%);
        }

        .Enter,
        .EnterActive,
        .EnterDone {
            animation: ModalInStick 0.4s ease forwards;
        }

        .Exit,
        .ExitActive,
        .ExitDone {
            animation: ModalOutStick 0.4s ease forwards;
        }
    }
}

.Enter,
.EnterActive,
.EnterDone {
    animation: ModalIn 0.4s ease forwards;
}

.Exit,
.ExitActive,
.ExitDone {
    animation: ModalOut 0.4s ease forwards;
}

.StatusEnter {
    transform: translateY(-100%);
}

.StatusEnterActive {
    transform: translateY(0) !important;
    transition: transform 500ms;
}

.StatusEnterDone {
    transform: translateY(0) !important;
}

.StatusExit {
    transform: translateY(0) !important;
}

.StatusExitActive {
    transform: translateY(-100%) !important;
    transition: transform 500ms;
}
